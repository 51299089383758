import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "src/environments/environment";

interface HealthcheckState {
  msg: string;
  version: string;
  error: string | null;
}

const initialState: HealthcheckState = {
  msg: "",
  version: "",
  error: null,
};

export const fetchHealthcheck = createAsyncThunk(
  "healthcheck/fetchHealthcheck",
  async () => {
    const response = await axios.get(`${environment.apiUrl}healthCheck`);
    return response.data;
  }
);

const healthcheckSlice = createSlice({
  name: "healthcheck",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHealthcheck.pending, (state) => {
        state.msg = "";
        state.version = "";
        state.error = null;
      })
      .addCase(
        fetchHealthcheck.fulfilled,
        (state, action: PayloadAction<{ msg: string; version: string }>) => {
          state.msg = action.payload.msg;
          state.version = action.payload.version;
          state.error = null;
        }
      )
      .addCase(fetchHealthcheck.rejected, (state, action) => {
        state.msg = "";
        state.version = "";
        state.error =
          action.error.message || "Failed to fetch health check status";
      });
  },
});

export default healthcheckSlice.reducer;
