<table #infectionRecords class="cp-table-2 record-tables">
  <thead>
    <tr class="cp-table-2__header-width table-header cp-table-2__main-header">
      <th>
        <div
          class="inf-data-head display-flex justify-content-between"
          *ngIf="infectionBundle?._id"
        >
          <div class="timestamp display-flex flex-column">
            <div class="date">
              {{ infectionBundle?.timestamp | timezone : "MMM D" }}
            </div>
            <div class="time">
              {{ infectionBundle?.timestamp | timezone : "HH:mm" }}
              <cp-timezone-label></cp-timezone-label>
            </div>
          </div>
          <div class="icons">
            <img src="assets/icons/edit.svg" (click)="editCheckList()" alt="" />
            <mat-icon
              class="cp-mat-icon-overflow-initial mat-icon-comment"
              [ngClass]="{
                active: infectionBundle?.comment
              }"
              popoverTrigger="click"
              [cpPopover]="commentViewBlock"
              (click)="commentColumnDataStore = infectionBundle"
              position="bottom-end"
              svgIcon="cp-comment_grey"
            ></mat-icon>
            <mat-icon
              class="primary"
              inline="true"
              (mouseenter)="
                infectionBundle?.createdBy?.name
                  ? (tooltipStore = infectionBundle)
                  : (tooltipStore = null)
              "
              (mouseleave)="tooltipStore = null"
              popoverTrigger="hover"
              [cpPopover]="tooltipStore && popoverItem"
              position="bottom-end"
              >info</mat-icon
            >
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="{ isHeader: infQ?.type === questionTypes.HEADER }"
      *ngFor="let infQ of defaultQuestions; index as questionIndex"
    >
      <td>
        <ng-container
          *ngIf="
            isEditTable &&
              editCheckListStore?.questions?.at(questionIndex)?.get('type')
                ?.value !== questionTypes.HEADER;
            else showRecord
          "
        >
          <mat-checkbox
            [formControl]="
              editCheckListStore?.questions?.at(questionIndex)?.get('value')
            "
          >
          </mat-checkbox>
        </ng-container>
        <ng-template #showRecord>
          <mat-icon
            class="cp-icon"
            *ngIf="
              (
                infectionBundle?.questions
                | findObjectInArrayWithKey : 'question' : infQ?.name
              )?.value
            "
            >done</mat-icon
          >
        </ng-template>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td>
        <button
          class="mat-flat-button button-primary"
          *ngIf="isEditTable"
          [disabled]="
            !editCheckListStore?.questions?.dirty ||
            !editCheckListStore?.questions?.valid ||
            isEditInfectionBundleEmpty ||
            (infectionLoading$ | async)
          "
          (click)="submitCheckList()"
        >
          Submit
        </button>
      </td>
    </tr>
  </tfoot>
</table>

<ng-template #popoverItem>
  <div class="popover-cont">
    <div class="tp-head">Entered by</div>
    <p class="tp-body display-flex flex-column">
      <span>
        {{ tooltipStore?.createdBy?.name || "-" }} on
        {{ tooltipStore?.createdBy?.at | timezone : "HH:mm DD-MM-yyyy" }}
        <cp-timezone-label></cp-timezone-label
      ></span>
    </p>
    <ng-container *ngIf="tooltipStore?.updatedBy?.name">
      <div class="tp-head">Edited by</div>
      <p class="tp-body display-flex flex-column">
        <span>
          {{ tooltipStore?.updatedBy?.name || "-" }} on
          {{ tooltipStore?.updatedBy?.at | timezone : "HH:mm DD-MM-yyyy" }}
          <cp-timezone-label></cp-timezone-label
        ></span>
      </p>
    </ng-container>
  </div>
</ng-template>

<ng-template #commentViewBlock>
  <div class="comment-block" #commentInputViewBlock>
    <ng-container
      *ngIf="
        commentColumnDataStore?.comment && !isCommentEdit;
        else commentInput
      "
    >
      <div class="input-block display-flex">
        <span class="mr-1 comment-text">{{
          commentColumnDataStore?.comment
        }}</span>
        <img
          class="cursor-pointer"
          src="assets/icons/edit.svg"
          (click)="triggerCommentEdit($event)"
          alt=""
        />
      </div>
    </ng-container>
    <ng-template #commentInput>
      <div class="input-block">
        <textarea
          type="text"
          [formControl]="commentFormControl"
          placeholder="Add a comment"
        ></textarea>
        <hr />
        <div class="footer display-flex justify-content-end cursor-pointer">
          <mat-icon
            class="cp-mat-icon-overflow-initial"
            [ngClass]="{
              active: commentFormControl?.dirty && commentFormControl?.valid
            }"
            (click)="saveInputComment()"
            svgIcon="cp-upload_circle_grey"
          ></mat-icon>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
