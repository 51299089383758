import React from "react";
import { Provider } from "react-redux";
import store from "./reactStore/reduxStore";
import ShowIcdModal from "./showIcdModal/showIcdModal";

interface AppProps {
  onClose: () => void;
}

const App: React.FC<AppProps> = ({ onClose }) => (
  <Provider store={store}>
    <div>
      <ShowIcdModal onClose={onClose} />
    </div>
  </Provider>
);

export default App;
