<mat-accordion class="accordion" multi>
  <mat-expansion-panel
    class="trigger-panel"
    [expanded]="true"
    (opened)="setStep(0)"
    [hidden]="sortMed.length == 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Drug Orders </mat-panel-title>
      <mat-panel-description>
        <app-print-orders
          orderType="drug"
          *ngIf="state?.type == 'active'"
          [currentPatient]="currentPatient"
        ></app-print-orders>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table
      mat-table
      [dataSource]="
        sortMed
          | orderFilter
            : filterControls.orderName
            : filterControls.route
            : filterControls.protocol
            : filterControls.status
      "
    >
      <ng-container matColumnDef="atcClass">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1">Class</th>
        <td
          mat-cell
          class="firstCol"
          [attr.rowspan]="getRowSpanOfDrug('atcClass', i)"
          [style.display]="getRowSpanOfDrug('atcClass', i) ? '' : 'none'"
          *matCellDef="let order; let i = index"
        >
          <div
            class="order-name-text order-highlight"
            style="max-width: 80px"
            [matTooltip]="order.atcClass?.length >= 15 ? order.atcClass : ''"
            matTooltipClass="cp-mat-tooltip"
            [appTextHighlight]="highlightText"
            #orderSearch
          >
            <b>{{ order.atcClass ? order.atcClass : "Others" }}</b>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="orderName">
        <th mat-header-cell *matHeaderCellDef class="cell-width-2">Order</th>
        <td mat-cell *matCellDef="let order">
          <span class="display-flex">
            <span [ngSwitch]="order.type" class="order-name">
              <span *ngSwitchCase="'medications'">
                <div>
                  <img
                    *ngIf="!order.combination?.length"
                    alt="medication icon"
                    [src]="
                      order?.statusMessage
                        ? 'assets/icons/med_integration.svg'
                        : 'assets/icons/order_med.svg'
                    "
                    matTooltip="Medications"
                  />
                  <img
                    *ngIf="order.combination?.length"
                    src="assets/icons/sub_order_med.svg"
                    matTooltip="Medications"
                  />
                </div>

                <div *ngFor="let med of order.combination; last as isLast">
                  <img
                    *ngIf="!isLast"
                    src="assets/icons/sub_order_med.svg"
                    matTooltip="Medications"
                  />

                  <img
                    *ngIf="isLast"
                    src="assets/icons/sub_order_med_last.svg"
                    matTooltip="Medications"
                  />
                </div>
              </span>
            </span>

            <span *ngIf="order.protocol" class="protocol-icon">
              <img src="assets/icons/protocol_icon.svg" />
            </span>
            <span>
              <div
                *ngIf="order.type === 'medications'"
                class="order-name-text order-highlight"
                [matTooltip]="order.name?.length >= 15 ? order.name : ''"
                matTooltipClass="cp-mat-tooltip"
                [appTextHighlight]="highlightText"
                #orderSearch
              >
                <b>{{ order.name }}</b>
              </div>
              <ng-container
                *ngIf="
                  order.type === 'medications' && order.combination?.length
                "
              >
                <div
                  class="order-comb order-highlight order-name-text"
                  [appTextHighlight]="highlightText"
                  *ngFor="let med of order.combination"
                  #orderSearch
                >
                  <b
                    [matTooltip]="med.name?.length >= 15 ? med.name : ''"
                    matTooltipClass="cp-mat-tooltip"
                    >{{ med.name }}</b
                  >
                </div>
              </ng-container>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="brandname">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1">Brand</th>
        <td mat-cell *matCellDef="let order">
          <div
            *ngIf="order.brandName != null || order.brandName != undefined"
            class="order-name-text order-highlight"
            [matTooltip]="order.brandName?.length >= 15 ? order.brandName : ''"
            matTooltipClass="cp-mat-tooltip"
            [appTextHighlight]="highlightText"
            #orderSearch
          >
            {{ order.brandName | titlecase }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="days">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="state.type !== 'pending'">Days</span>
        </th>
        <td mat-cell *matCellDef="let order">
          <div
            *ngIf="
              order
                | daysPassed
                  : state.type
                  : true
                  : (getICUDischargeDate$ | async) as daysPassed
            "
          >
            <span *ngIf="state.type !== 'pending'">
              {{
                daysPassed.day && daysPassed.day > 0
                  ? "Day " + daysPassed.day
                  : "Scheduled"
              }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="dosage">
        <th mat-header-cell *matHeaderCellDef>Dosage</th>
        <td mat-cell *matCellDef="let order">
          <div *ngIf="order.type === 'medications'">
            {{ order.quantity }}
            {{ order.maxDose ? "- " + order.maxDose + " " : " " }}
            {{ order.unit }}
          </div>

          <div *ngIf="order.type !== 'medications'">
            {{
              order?.rate?.value
                ? order?.rate?.value + "/" + order?.rate?.unit
                : ""
            }}
            {{
              order?.quantity ? order?.quantity + " " + order.quantityUnit : ""
            }}
          </div>

          <ng-container
            *ngIf="order.type === 'medications' && order.combination?.length"
          >
            <div class="order-comb" *ngFor="let med of order.combination">
              {{ med.quantity }} {{ med.unit }}
            </div>
          </ng-container>

          <div *ngIf="order.type !== 'medications' && order.numberOfDoses">
            {{ order.numberOfDoses }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1">
          Frequency
        </th>
        <td mat-cell *matCellDef="let order">
          <span
            *ngIf="order.frequency && (order.frequency.fType || order.sos)"
            class=""
          >
            <span *ngIf="order.frequency.fType == 'continuous'"
              >Continuous</span
            >
            <span *ngIf="order.frequency.fType == 'once'">Once</span>
            <span *ngIf="order.frequency.fType == 'after'"
              >After {{ order.frequency.timeOfDay }}</span
            >
            <span *ngIf="order.frequency.fType == 'before'"
              >Before {{ order.frequency.timeOfDay }}</span
            >
            <span *ngIf="order.frequency.fType == 'every'"
              >Every
              <span *ngIf="order.frequency.days; else elseHours">
                {{ order.frequency.days }} days
              </span>
              <ng-template #elseHours>
                <span
                  >{{
                    order.frequency.hours ? order.frequency.hours : 0
                  }}
                  hours</span
                >
                <span>{{
                  order.frequency.mins
                    ? " " + order.frequency.mins + " mins"
                    : ""
                }}</span>
              </ng-template>
            </span>
            <span *ngIf="order.sos"> SOS</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="route">
        <th mat-header-cell *matHeaderCellDef>Route</th>
        <td mat-cell *matCellDef="let order">
          <span *ngIf="order.type === 'medications'"
            >{{ order.route || "-" }}
          </span>

          <span *ngIf="order.name === 'NPO'" class="cp-badge-danger">NPO</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="otherInfo">
        <th mat-header-cell *matHeaderCellDef class="cell-other-info">
          Other Info
        </th>
        <td mat-cell *matCellDef="let order">
          <span *ngIf="order.laterality">
            Laterality : {{ order.laterality }}<br />
          </span>
          <span *ngIf="order.site">Site : {{ order.site }} <br /></span>
          <span *ngIf="order.discipline"
            >Discipline : {{ order.discipline }}<br />
          </span>
          <span *ngIf="order.specimenType"
            >Specimen Type : {{ order.specimenType }}<br />
          </span>
          <ng-container *ngIf="order?.referenceLabId">
            Ref No : {{ order?.referenceLabId }}
          </ng-container>
          <cp-status-chip
            *ngIf="order?.type == 'medications' && order?.statusMessage"
            class="display-flex"
            [statusConfig]="{
              buttonText: order?.statusMessage,
              colorClass: 'order__cool-grey-300',
              buttonWidth: 'auto'
            }"
          ></cp-status-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef class="cell-width-2">
          Date & Time
        </th>
        <td mat-cell *matCellDef="let order">
          <div class="display-flex flex-column">
            <div>Start: {{ order.startTime | timezone : "DD-MM-yyyy," }}</div>
            <div>
              {{ order.startTime | timezone : "HH:mm" }} hrs
              <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
              &nbsp;
              <span *ngIf="order.urgency == 'STAT'" class="cp-badge urgent-med"
                ><strong>STAT</strong></span
              >
              <span *ngIf="order.urgency == 'ASAP'" class="cp-badge urgent-med"
                ><strong>ASAP</strong></span
              >
            </div>
          </div>
          <div class="cp-mt-1">
            <span *ngIf="order.category === 'completed'">
              <div class="display-flex flex-column">
                <div>
                  Completed:&nbsp;{{
                    order.updatedAt | timezone : "DD-MM-yyyy,"
                  }}
                </div>
                <div>
                  {{ order.updatedAt | timezone : "HH:mm" }}
                  hrs
                  <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
                </div>
              </div>
            </span>
            <span *ngIf="state.type === 'inactive'">
              <div class="display-flex flex-column">
                <div>
                  Discontinued:&nbsp;{{
                    order.updatedAt | timezone : "DD-MM-yyyy,"
                  }}
                </div>
                <div>
                  {{ order.updatedAt | timezone : "HH:mm" }}
                  hrs
                  <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
                </div>
              </div>
            </span>
            <span
              *ngIf="
                state.type !== 'inactive' &&
                state.type !== 'completed' &&
                order.endTime
              "
            >
              <div class="display-flex flex-column">
                <div>
                  End:&nbsp;{{ order.endTime | timezone : "DD-MM-yyyy," }}
                </div>
                <div>
                  {{ order.endTime | timezone : "HH:mm" }}
                  hrs
                  <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
                </div>
              </div>
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="cell-width-3">
          <span
            class="status-header"
            *ngIf="state.type !== 'pending' && state.type !== 'pta'"
            >Status</span
          >
        </th>
        <td mat-cell *matCellDef="let order">
          <ng-template [ngxPermissionsOnly]="['place_order']">
            <button
              mat-flat-button
              class="button-primary"
              *ngIf="state.type === 'pta'"
              (click)="orderToHospital(order); $event.stopPropagation()"
            >
              Order In Hospital
            </button>
          </ng-template>
          <div *ngIf="order.bedsideOrder">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Ordered by bedside',
                  isBedSide: order.bedsideOrder,
                  tooltipText: 'Click to complete order',
                  className: 'status-text bedside-order'
                }
              "
            >
            </ng-container>
          </div>

          <ng-template
            [ngxPermissionsOnly]="['sign_order']"
            *ngIf="!isDischargeTimeElapsed"
          >
            <div *ngIf="state.sign">
              <label
                (click)="signed(order)"
                [matTooltipDisabled]="!order.replaceOrderId"
                [matTooltip]="linkedOrderTooltip"
                class="btn btn-sm mr-1 sign-label status-text order-action-yellow"
              >
                Sign
                <span *ngIf="patientName"
                  >for
                  {{
                    patientName.length > 10
                      ? patientName.slice(0, 10) + "..."
                      : patientName
                  }}</span
                >
              </label>
            </div>
          </ng-template>
          <span *ngIf="order.state == 'red' && !order.bedsideOrder">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Not Communicated',
                  tooltipText:
                    'Click after order has been communicated to bedside nurse',
                  className: 'status-text cp-badge-danger'
                }
              "
            >
            </ng-container>
          </span>
          <span *ngIf="order.state == 'yellow'">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Communicated',
                  tooltipText:
                    'Click after order has been acted upon by bedside nurse',
                  className: 'status-text order-action-yellow'
                }
              "
            >
            </ng-container>
          </span>
          <span *ngIf="order.state == 'green'">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Acted upon',
                  tooltipText: 'Click to complete order',
                  className: 'status-text order-action-green'
                }
              "
            >
            </ng-container>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewDetails">
        <th mat-header-cell *matHeaderCellDef class="cell-width-2"></th>

        <td mat-cell *matCellDef="let order">
          <button
            mat-flat-button
            class="button-primary"
            (click)="clickDetailsBtn(order)"
          >
            View details
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit-discontinue">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1"></th>

        <td mat-cell *matCellDef="let order" class="text-center">
          <ng-template [ngxPermissionsOnly]="['edit_order']">
            <span
              *ngIf="
                orders$
                  | async
                  | canEditOrder : order._id : state.role : order.type;
                else disableEditIcon
              "
            >
              <span
                *ngIf="state.edit && !isDischargeTimeElapsed"
                class="edit-icon"
                matTooltip="Edit Order"
                (click)="modify(order); $event.stopPropagation()"
                [disabled]="editing$ | async"
              >
                <img src="assets/icons/edit.svg" />
              </span>
            </span>

            <ng-template #disableEditIcon>
              <span
                *ngIf="state.edit && !isDischargeTimeElapsed"
                class="edit-icon"
                matTooltip="A pended order is already present for this order"
              >
                <img src="assets/icons/edit_disabled.svg" />
              </span>
            </ng-template>

            <a
              *ngIf="state.revert && isDischargeTimeElapsed === false"
              class="p-2"
              matTooltip="Revert to Active orders"
              (click)="openConfirmationWindow(order); $event.stopPropagation()"
            >
              <fa-icon [icon]="['fas', 'arrow-up']" size="lg"> </fa-icon>
            </a>
          </ng-template>

          <ng-template [ngxPermissionsOnly]="['discontinue_order']">
            <span
              *ngIf="
                orders$
                  | async
                  | canEditOrder : order._id : state.role : order.type;
                else disableDiscontinueIcon
              "
            >
              <span
                *ngIf="
                  (state.discard || state.delete) && !isDischargeTimeElapsed
                "
              >
                <img
                  *ngIf="state.type !== 'pending' && state.type !== 'pta'"
                  class="cp-cursor-pointer"
                  src="assets/icons/cancel_rounded.svg"
                  matTooltip="Discontinue order"
                  (click)="
                    openDialog(discontinueModal, order, 650);
                    $event.stopPropagation()
                  "
                />

                <img
                  *ngIf="state.type === 'pending' || state.type === 'pta'"
                  class="cp-cursor-pointer"
                  src="assets/icons/delete.svg"
                  matTooltip="Delete order"
                  (click)="
                    openDialog(discontinueModal, order, 650);
                    $event.stopPropagation()
                  "
                />
              </span>
            </span>

            <ng-template #disableDiscontinueIcon>
              <span
                *ngIf="
                  (state.discard || state.delete) && !isDischargeTimeElapsed
                "
                class="discontinue-icon"
                matTooltip="A pended order is already present for this order"
              >
                <img src="assets/icons/cancel_disabled.svg" />
              </span>
            </ng-template>
          </ng-template>

          <ng-template [ngxPermissionsOnly]="['place_order']">
            <img
              src="assets/icons/undo.svg"
              class="cp-cursor-pointer"
              *ngIf="state.type === 'inactive' && !order.disableReorder"
              matTooltip="Reorder"
              (click)="openConfirmationWindow(order); $event.stopPropagation()"
            />
            <img
              src="assets/icons/not_available.svg"
              *ngIf="state.type === 'inactive' && order.disableReorder"
              matTooltip="No longer available"
            />
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="DrugOrderTableColumns"></tr>

      <tr
        mat-row
        *matRowDef="let row; columns: DrugOrderTableColumns"
        [class.order-row-selected]="
          checkIfOrderExistInProtocol(row, activeProtocols)
        "
        (contextmenu)="onContextMenu($event, row)"
        [ngClass]="{ 'make-border': row.last == true }"
      ></tr>
      <!-- [ngClass]="{ 'make-border': row.last == true }" -->
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="trigger-panel"
    [expanded]="true"
    (opened)="setStep(1)"
    [hidden]="sortNonMed.length == 0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Non-Drug Orders </mat-panel-title>
      <mat-panel-description>
        <app-print-orders
          orderType="nonDrug"
          *ngIf="state?.type == 'active'"
          [currentPatient]="currentPatient"
          [type]="state?.type || null"
        ></app-print-orders>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <table
      mat-table
      [dataSource]="
        sortNonMed
          | orderFilter
            : filterControls.orderName
            : filterControls.route
            : filterControls.protocol
            : filterControls.status
      "
    >
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1">Class</th>
        <td
          mat-cell
          class="firstCol"
          *matCellDef="let order; let i = index"
          [attr.rowspan]="getRowSpanOfNOnDrug('type', i)"
          [style.display]="getRowSpanOfNOnDrug('type', i) ? '' : 'none'"
        >
          <div
            class="order-name-text order-highlight"
            [appTextHighlight]="highlightText"
            #orderSearch
          >
            <span
              ><b>{{
                nonDrugClassToTextMap[order.type] || order.type
              }}</b></span
            >
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="orderName">
        <th mat-header-cell *matHeaderCellDef class="cell-width-2">Order</th>
        <td mat-cell *matCellDef="let order">
          <span class="display-flex">
            <span [ngSwitch]="order.type" class="order-name">
              <img
                *ngSwitchCase="'diets'"
                src="assets/icons/order_diet.svg"
                matTooltip="Diets"
              />
              <img
                *ngSwitchCase="'communications'"
                src="assets/icons/order_communication.svg"
                matTooltip="Communications"
              />
              <img
                *ngSwitchCase="'bloods'"
                src="assets/icons/order_blood.svg"
                matTooltip="Bloods"
              />
              <img
                *ngSwitchCase="'labs'"
                [src]="
                  order?.referenceLabId
                    ? 'assets/icons/icomoon_free_lab.svg'
                    : 'assets/icons/order_lab.svg'
                "
                matTooltip="Labs"
              />
              <span *ngSwitchCase="'medications'">
                <div>
                  <img
                    *ngIf="!order.combination?.length"
                    src="assets/icons/order_med.svg"
                    matTooltip="Medications"
                  />
                  <img
                    *ngIf="order.combination?.length"
                    src="assets/icons/sub_order_med.svg"
                    matTooltip="Medications"
                  />
                </div>

                <div *ngFor="let med of order.combination; last as isLast">
                  <img
                    *ngIf="!isLast"
                    src="assets/icons/sub_order_med.svg"
                    matTooltip="Medications"
                  />

                  <img
                    *ngIf="isLast"
                    src="assets/icons/sub_order_med_last.svg"
                    matTooltip="Medications"
                  />
                </div>
              </span>

              <img
                *ngSwitchCase="'procedures'"
                src="assets/icons/order_procedure.svg"
                matTooltip="Procedures"
              />
              <img
                *ngSwitchCase="'vents'"
                src="assets/icons/order_vent.svg"
                matTooltip="Vents"
              />
            </span>

            <span *ngIf="order.protocol" class="protocol-icon">
              <img src="assets/icons/protocol_icon.svg" />
            </span>
            <span>
              <div
                class="order-name-text"
                class="order-highlight"
                [appTextHighlight]="highlightText"
                [matTooltip]="order.name?.length >= 15 ? order.name : ''"
                matTooltipClass="cp-mat-tooltip"
                #orderSearch
              >
                <b>{{ order.name }}</b>
              </div>
              <ng-container
                *ngIf="
                  order.type === 'medications' && order.combination?.length
                "
              >
                <div
                  class="order-comb order-highlight"
                  [appTextHighlight]="highlightText"
                  *ngFor="let med of order.combination"
                  #orderSearch
                >
                  <b
                    class="order-name-text"
                    [matTooltip]="med.name?.length >= 15 ? med.name : ''"
                    matTooltipClass="cp-mat-tooltip"
                    >{{ med.name }}</b
                  >
                </div>
              </ng-container>
            </span>
            <span *ngIf="order.type === 'procedures'">
              <div
                class="order-name-text order-highlight"
                [appTextHighlight]="highlightText"
                #orderSearch
              >
                <b
                  [matTooltip]="order.pType?.length >= 15 ? order.pType : ''"
                  matTooltipClass="cp-mat-tooltip"
                  >{{ order.pType }}</b
                >
              </div>
            </span>
            <span *ngIf="order.type === 'vents'">
              <div
                class="order-name-text order-highlight"
                [appTextHighlight]="highlightText"
                #orderSearch
              >
                <b
                  [matTooltip]="order.airway?.length >= 15 ? order.airway : ''"
                  matTooltipClass="cp-mat-tooltip"
                  >{{ order.airway }}</b
                >
              </div>
            </span>
            <span
              *ngIf="order.type === 'bloods' || order.type === 'communications'"
            >
              <div
                class="order-name-text order-highlight"
                [appTextHighlight]="highlightText"
                #orderSearch
              >
                <b
                  [matTooltip]="order.title?.length >= 15 ? order.title : ''"
                  matTooltipClass="cp-mat-tooltip"
                  >{{ order.title }}</b
                >
              </div>
            </span>
            <span *ngIf="order.type === 'labs'">
              <div
                class="order-name-text order-highlight"
                [appTextHighlight]="highlightText"
                #orderSearch
              >
                <b
                  [matTooltip]="
                    order.investigation?.length >= 15 ? order.investigation : ''
                  "
                  matTooltipClass="cp-mat-tooltip"
                  >{{ order.investigation }}</b
                >
              </div>
            </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="brandname">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1">Brand</th>
        <td mat-cell *matCellDef="let order">
          <div
            *ngIf="order.brandName != null || order.brandName != undefined"
            class="order-name-text order-highlight"
            [matTooltip]="order.brandName?.length >= 15 ? order.brandName : ''"
            matTooltipClass="cp-mat-tooltip"
            [appTextHighlight]="highlightText"
            #orderSearch
          >
            {{ order.brandName | titlecase }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="days">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="state.type !== 'pending'">Days</span>
        </th>
        <td mat-cell *matCellDef="let order">
          <div
            *ngIf="
              order
                | daysPassed
                  : state.type
                  : true
                  : (getICUDischargeDate$ | async) as daysPassed
            "
          >
            <span *ngIf="state.type !== 'pending'">
              {{
                daysPassed.day && daysPassed.day > 0
                  ? "Day " + daysPassed.day
                  : "Scheduled"
              }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="dosage">
        <th mat-header-cell *matHeaderCellDef>Dosage</th>
        <td mat-cell *matCellDef="let order">
          <div *ngIf="order.type === 'medications'">
            {{ order.quantity }}
            {{ order.maxDose ? "- " + order.maxDose + " " : " " }}
            {{ order.unit }}
          </div>

          <div *ngIf="order.type !== 'medications'">
            {{
              order?.rate?.value
                ? order?.rate?.value + "/" + order?.rate?.unit
                : ""
            }}
            {{
              order?.quantity ? order?.quantity + " " + order.quantityUnit : ""
            }}
          </div>

          <ng-container
            *ngIf="order.type === 'medications' && order.combination?.length"
          >
            <div class="order-comb" *ngFor="let med of order.combination">
              {{ med.quantity }} {{ med.unit }}
            </div>
          </ng-container>

          <div *ngIf="order.type !== 'medications' && order.numberOfDoses">
            {{ order.numberOfDoses }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1">
          Frequency
        </th>
        <td mat-cell *matCellDef="let order">
          <span
            *ngIf="order.frequency && (order.frequency.fType || order.sos)"
            class=""
          >
            <span *ngIf="order.frequency.fType == 'continuous'"
              >Continuous</span
            >
            <span *ngIf="order.frequency.fType == 'once'">Once</span>
            <span *ngIf="order.frequency.fType == 'after'"
              >After {{ order.frequency.timeOfDay }}</span
            >
            <span *ngIf="order.frequency.fType == 'before'"
              >Before {{ order.frequency.timeOfDay }}</span
            >
            <span *ngIf="order.frequency.fType == 'every'"
              >Every
              <span *ngIf="order.frequency.days; else elseHours">
                {{ order.frequency.days }} days
              </span>
              <ng-template #elseHours>
                <span
                  >{{
                    order.frequency.hours ? order.frequency.hours : 0
                  }}
                  hours</span
                >
                <span>{{
                  order.frequency.mins
                    ? " " + order.frequency.mins + " mins"
                    : ""
                }}</span>
              </ng-template>
            </span>
            <span *ngIf="order.sos"> SOS</span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="route">
        <th mat-header-cell *matHeaderCellDef>Route</th>
        <td mat-cell *matCellDef="let order">
          <span *ngIf="order.type === 'medications'"
            >{{ order.route || "-" }}
          </span>

          <span *ngIf="order.name === 'NPO'" class="cp-badge-danger">NPO</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="otherInfo">
        <th mat-header-cell *matHeaderCellDef class="cell-other-info">
          Other Info
        </th>
        <td mat-cell *matCellDef="let order">
          <span *ngIf="order.laterality">
            Laterality : {{ order.laterality }}<br />
          </span>
          <span *ngIf="order.site">Site : {{ order.site }} <br /></span>
          <span *ngIf="order.discipline"
            >Discipline : {{ order.discipline }}<br />
          </span>
          <span *ngIf="order.specimenType"
            >Specimen Type : {{ order.specimenType }}<br />
          </span>
          <span>
            <ng-container *ngIf="order?.labId; else refNoBlock">
              Lab Id : {{ order.labId }}<br />
            </ng-container>
            <ng-template #refNoBlock>
              <ng-container *ngIf="order?.referenceLabId">
                Ref No : {{ order?.referenceLabId }}
              </ng-container>
            </ng-template>
          </span>
          <cp-status-chip
            *ngIf="order?.referenceLabId || order?.labId"
            class="display-flex"
            [statusConfig]="{
              buttonText: order?.labId ? 'Sample received' : 'Acknowledged',
              colorClass: 'order__cool-grey-300',
              buttonWidth: 'auto'
            }"
          ></cp-status-chip>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef class="cell-width-2">
          Date & Time
        </th>
        <td mat-cell *matCellDef="let order">
          <div class="display-flex flex-column">
            <div>Start: {{ order.startTime | timezone : "DD-MM-yyyy," }}</div>
            <div>
              {{ order.startTime | timezone : "HH:mm" }} hrs
              <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
              &nbsp;
              <span *ngIf="order.urgency == 'STAT'" class="cp-badge urgent-med"
                ><strong>STAT</strong></span
              >
              <span *ngIf="order.urgency == 'ASAP'" class="cp-badge urgent-med"
                ><strong>ASAP</strong></span
              >
            </div>
          </div>
          <div class="cp-mt-1">
            <span *ngIf="order.category === 'completed'">
              <div class="display-flex flex-column">
                <div>
                  Completed:&nbsp;{{
                    order.updatedAt | timezone : "DD-MM-yyyy,"
                  }}
                </div>
                <div>
                  {{ order.updatedAt | timezone : "HH:mm" }}
                  hrs
                  <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
                </div>
              </div>
            </span>
            <span *ngIf="state.type === 'inactive'">
              <div class="display-flex flex-column">
                <div>
                  Discontinued:&nbsp;{{
                    order.updatedAt | timezone : "DD-MM-yyyy,"
                  }}
                </div>
                <div>
                  {{ order.updatedAt | timezone : "HH:mm" }}
                  hrs
                  <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
                </div>
              </div>
            </span>
            <span
              *ngIf="
                state.type !== 'inactive' &&
                state.type !== 'completed' &&
                order.endTime
              "
            >
              <div class="display-flex flex-column">
                <div>
                  End:&nbsp;{{ order.endTime | timezone : "DD-MM-yyyy," }}
                </div>
                <div>
                  {{ order.endTime | timezone : "HH:mm" }}
                  hrs
                  <ng-template [ngTemplateOutlet]="timeZoneLabel"></ng-template>
                </div>
              </div>
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="cell-width-3">
          <span
            class="status-header"
            *ngIf="state.type !== 'pending' && state.type !== 'pta'"
            >Status</span
          >
        </th>
        <td mat-cell *matCellDef="let order">
          <ng-template [ngxPermissionsOnly]="['place_order']">
            <button
              mat-flat-button
              class="button-primary"
              *ngIf="state.type === 'pta'"
              (click)="orderToHospital(order); $event.stopPropagation()"
            >
              Order In Hospital
            </button>
          </ng-template>
          <div *ngIf="order.bedsideOrder">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Ordered by bedside',
                  isBedSide: order.bedsideOrder,
                  tooltipText: 'Click to complete order',
                  className: 'status-text bedside-order'
                }
              "
            >
            </ng-container>
          </div>

          <ng-template
            [ngxPermissionsOnly]="['sign_order']"
            *ngIf="!isDischargeTimeElapsed"
          >
            <div *ngIf="state.sign">
              <label
                (click)="signed(order)"
                [matTooltipDisabled]="!order.replaceOrderId"
                [matTooltip]="linkedOrderTooltip"
                class="btn btn-sm mr-1 sign-label status-text order-action-yellow"
              >
                Sign
                <span *ngIf="patientName"
                  >for
                  {{
                    patientName.length > 10
                      ? patientName.slice(0, 10) + "..."
                      : patientName
                  }}</span
                >
              </label>
            </div>
          </ng-template>
          <span *ngIf="order.state == 'red' && !order.bedsideOrder">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Not Communicated',
                  tooltipText:
                    'Click after order has been communicated to bedside nurse',
                  className: 'status-text cp-badge-danger'
                }
              "
            >
            </ng-container>
          </span>
          <span *ngIf="order.state == 'yellow'">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Communicated',
                  tooltipText:
                    'Click after order has been acted upon by bedside nurse',
                  className: 'status-text order-action-yellow'
                }
              "
            >
            </ng-container>
          </span>
          <span *ngIf="order.state == 'green'">
            <ng-container
              *ngTemplateOutlet="
                orderState;
                context: {
                  state: state,
                  order: order,
                  text: 'Acted upon',
                  tooltipText: 'Click to complete order',
                  className: 'status-text order-action-green'
                }
              "
            >
            </ng-container>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewDetails">
        <th mat-header-cell *matHeaderCellDef class="cell-width-2"></th>

        <td mat-cell *matCellDef="let order">
          <button
            mat-flat-button
            class="button-primary"
            (click)="clickDetailsBtn(order)"
          >
            View details
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit-discontinue">
        <th mat-header-cell *matHeaderCellDef class="cell-width-1"></th>

        <td mat-cell *matCellDef="let order" class="text-center">
          <ng-template [ngxPermissionsOnly]="['edit_order']">
            <span
              *ngIf="
                orders$
                  | async
                  | canEditOrder : order._id : state.role : order.type;
                else disableEditIcon
              "
            >
              <span
                *ngIf="state.edit && !isDischargeTimeElapsed"
                class="edit-icon"
                matTooltip="Edit Order"
                (click)="modify(order); $event.stopPropagation()"
                [disabled]="editing$ | async"
              >
                <img src="assets/icons/edit.svg" />
              </span>
            </span>

            <ng-template #disableEditIcon>
              <span
                *ngIf="state.edit && !isDischargeTimeElapsed"
                class="edit-icon"
                matTooltip="A pended order is already present for this order"
              >
                <img src="assets/icons/edit_disabled.svg" />
              </span>
            </ng-template>

            <a
              *ngIf="state.revert && isDischargeTimeElapsed === false"
              class="p-2"
              matTooltip="Revert to Active orders"
              (click)="openConfirmationWindow(order); $event.stopPropagation()"
            >
              <fa-icon [icon]="['fas', 'arrow-up']" size="lg"> </fa-icon>
            </a>
          </ng-template>

          <ng-template [ngxPermissionsOnly]="['discontinue_order']">
            <span
              *ngIf="
                orders$
                  | async
                  | canEditOrder : order._id : state.role : order.type;
                else disableDiscontinueIcon
              "
            >
              <span
                *ngIf="
                  (state.discard || state.delete) && !isDischargeTimeElapsed
                "
              >
                <img
                  *ngIf="state.type !== 'pending' && state.type !== 'pta'"
                  class="cp-cursor-pointer"
                  src="assets/icons/cancel_rounded.svg"
                  matTooltip="Discontinue order"
                  (click)="
                    openDialog(discontinueModal, order, 650);
                    $event.stopPropagation()
                  "
                />

                <img
                  *ngIf="state.type === 'pending' || state.type === 'pta'"
                  class="cp-cursor-pointer"
                  src="assets/icons/delete.svg"
                  matTooltip="Delete order"
                  (click)="
                    openDialog(discontinueModal, order, 650);
                    $event.stopPropagation()
                  "
                />
              </span>
            </span>

            <ng-template #disableDiscontinueIcon>
              <span
                *ngIf="
                  (state.discard || state.delete) && !isDischargeTimeElapsed
                "
                class="discontinue-icon"
                matTooltip="A pended order is already present for this order"
              >
                <img src="assets/icons/cancel_disabled.svg" />
              </span>
            </ng-template>
          </ng-template>

          <ng-template [ngxPermissionsOnly]="['place_order']">
            <img
              src="assets/icons/undo.svg"
              class="cp-cursor-pointer"
              *ngIf="state.type === 'inactive' && !order.disableReorder"
              matTooltip="Reorder"
              (click)="reOrderOrder(order); $event.stopPropagation()"
            />
            <img
              src="assets/icons/not_available.svg"
              *ngIf="state.type === 'inactive' && order.disableReorder"
              matTooltip="No longer available"
            />
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="nonDrugOrderTableColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: nonDrugOrderTableColumns"
        [class.order-row-selected]="
          checkIfOrderExistInProtocol(row, activeProtocols)
        "
        (contextmenu)="onContextMenu($event, row)"
        [ngClass]="{ 'make-border': row.last == true }"
      ></tr>
      <!-- [ngClass]="{ 'make-border': row.last == true }" -->
    </table>
  </mat-expansion-panel>
</mat-accordion>

<!-- <table
  mat-table
  [dataSource]="
    ordersView
      | orderFilter
        : filterControls.orderName
        : filterControls.route
        : filterControls.protocol
        : filterControls.status
  "
>
  <ng-container matColumnDef="orderName">
    <th mat-header-cell *matHeaderCellDef class="cell-width-3">Order</th>
    <td mat-cell *matCellDef="let order">
      <span class="display-flex">
        <span [ngSwitch]="order.type" class="order-name">
          <img
            *ngSwitchCase="'diets'"
            src="assets/icons/order_diet.svg"
            matTooltip="Diets"
          />
          <img
            *ngSwitchCase="'communications'"
            src="assets/icons/order_communication.svg"
            matTooltip="Communications"
          />
          <img
            *ngSwitchCase="'bloods'"
            src="assets/icons/order_blood.svg"
            matTooltip="Bloods"
          />
          <img
            *ngSwitchCase="'labs'"
            src="assets/icons/order_lab.svg"
            matTooltip="Labs"
          />
          <span *ngSwitchCase="'medications'">
            <div>
              <img
                *ngIf="!order.combination?.length"
                src="assets/icons/order_med.svg"
                matTooltip="Medications"
              />
              <img
                *ngIf="order.combination?.length"
                src="assets/icons/sub_order_med.svg"
                matTooltip="Medications"
              />
            </div>

            <div *ngFor="let med of order.combination; last as isLast">
              <img
                *ngIf="!isLast"
                src="assets/icons/sub_order_med.svg"
                matTooltip="Medications"
              />

              <img
                *ngIf="isLast"
                src="assets/icons/sub_order_med_last.svg"
                matTooltip="Medications"
              />
            </div>
          </span>

          <img
            *ngSwitchCase="'procedures'"
            src="assets/icons/order_procedure.svg"
            matTooltip="Procedures"
          />
          <img
            *ngSwitchCase="'vents'"
            src="assets/icons/order_vent.svg"
            matTooltip="Vents"
          />
        </span>

        <span *ngIf="order.protocol" class="protocol-icon">
          <img src="assets/icons/protocol_icon.svg" />
        </span>
        <span>
          <div
            class="order-name-text"
            [matTooltip]="order.name?.length > 20 ? order.name : ''"
            matTooltipClass="cp-mat-tooltip"
          >
            <b>{{ order.name }}</b>
          </div>
          <ng-container
            *ngIf="order.type === 'medications' && order.combination?.length"
          >
            <div class="order-comb" *ngFor="let med of order.combination">
              <b
                class="order-name-text"
                [matTooltip]="med.name?.length > 20 ? med.name : ''"
                matTooltipClass="cp-mat-tooltip"
                >{{ med.name }}</b
              >
            </div>
          </ng-container>
        </span>
        <span *ngIf="order.type === 'procedures'">
          <b
            class="order-name-text"
            [matTooltip]="order.pType?.length > 20 ? order.pType : ''"
            matTooltipClass="cp-mat-tooltip"
            >{{ order.pType }}</b
          >
        </span>
        <span *ngIf="order.type === 'vents'">
          <b
            class="order-name-text"
            [matTooltip]="order.airway?.length > 20 ? order.airway : ''"
            matTooltipClass="cp-mat-tooltip"
            >{{ order.airway }}</b
          >
        </span>
        <span
          *ngIf="order.type === 'bloods' || order.type === 'communications'"
        >
          <b
            class="order-name-text"
            [matTooltip]="order.title?.length > 20 ? order.title : ''"
            matTooltipClass="cp-mat-tooltip"
            >{{ order.title }}</b
          >
        </span>
        <span *ngIf="order.type === 'labs'">
          <b
            class="order-name-text"
            [matTooltip]="
              order.investigation?.length > 20 ? order.investigation : ''
            "
            matTooltipClass="cp-mat-tooltip"
            >{{ order.investigation }}</b
          >
        </span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="brandname">
    <th mat-header-cell *matHeaderCellDef class="cell-width-2">Brand</th>
    <td mat-cell *matCellDef="let order">
      <div
        *ngIf="order.brandName != null || order.brandName != undefined"
        style="max-width: 120px"
        class="order-name-text"
        [matTooltip]="order.brandName?.length > 20 ? order.brandName : ''"
        matTooltipClass="cp-mat-tooltip"
      >
        {{ order.brandName | titlecase }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="days">
    <th mat-header-cell *matHeaderCellDef>
      <span *ngIf="state.type !== 'pending'">Days</span>
    </th>
    <td mat-cell *matCellDef="let order">
      <div
        *ngIf="
          order
            | daysPassed
              : state.type
              : (getICUDischargeDate$ | async) as daysPassed
        "
      >
        <span *ngIf="state.type !== 'pending'">
          {{ daysPassed.day + 1 < 1 ? "NA" : daysPassed.day + 1 }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="dosage">
    <th mat-header-cell *matHeaderCellDef>Dosage</th>
    <td mat-cell *matCellDef="let order">
      <div *ngIf="order.type === 'medications'">
        {{ order.quantity }}
        {{ order.maxDose ? "- " + order.maxDose + " " : " " }} {{ order.unit }}
      </div>

      <ng-container
        *ngIf="order.type === 'medications' && order.combination?.length"
      >
        <div class="order-comb" *ngFor="let med of order.combination">
          {{ med.quantity }} {{ med.unit }}
        </div>
      </ng-container>

      <div *ngIf="order.type == 'diets' && order?.rate?.value != null">
        {{ order.rate.value }} ml/{{ order.rate.unit }}
      </div>

      <div *ngIf="order.type !== 'medications' && order.numberOfDoses">
        {{ order.numberOfDoses }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="frequency">
    <th mat-header-cell *matHeaderCellDef>Frequency</th>
    <td mat-cell *matCellDef="let order">
      <span
        *ngIf="order.frequency && (order.frequency.fType || order.sos)"
        class=""
      >
        <span *ngIf="order.frequency.fType == 'continuous'">Continuous</span>
        <span *ngIf="order.frequency.fType == 'once'">Once</span>
        <span *ngIf="order.frequency.fType == 'after'"
          >After {{ order.frequency.timeOfDay }}</span
        >
        <span *ngIf="order.frequency.fType == 'before'"
          >Before {{ order.frequency.timeOfDay }}</span
        >
        <span *ngIf="order.frequency.fType == 'every'"
          >Every
          <span *ngIf="order.frequency.days; else elseHours">
            {{ order.frequency.days }} days
          </span>
          <ng-template #elseHours>
            <span
              >{{
                order.frequency.hours ? order.frequency.hours : 0
              }}
              hours</span
            >
            <span>{{
              order.frequency.mins ? " " + order.frequency.mins + " mins" : ""
            }}</span>
          </ng-template>
        </span>
        <span *ngIf="order.sos"> SOS</span>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="route">
    <th mat-header-cell *matHeaderCellDef>Route</th>
    <td mat-cell *matCellDef="let order">
      <span *ngIf="order.type === 'medications'"
        >{{ order.route || "-" }}
      </span>

      <span *ngIf="order.name === 'NPO'" class="cp-badge-danger">NPO</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="otherInfo">
    <th mat-header-cell *matHeaderCellDef>Other Info</th>
    <td mat-cell *matCellDef="let order">
      <span *ngIf="order.laterality">
        Laterality : {{ order.laterality }}<br />
      </span>
      <span *ngIf="order.site">Site : {{ order.site }} <br /></span>
      <span *ngIf="order.discipline"
        >Discipline : {{ order.discipline }}<br />
      </span>
      <span *ngIf="order.specimenType"
        >Specimen Type : {{ order.specimenType }}<br />
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef class="cell-width-3">Date & Time</th>
    <td mat-cell *matCellDef="let order">
      <div>
        Start: {{ order.startTime | timezone: "DD-MM-yyyy, HH:mm" }} hrs
        <span *ngIf="order.urgency == 'STAT'" class="cp-badge urgent-med"
          ><strong>STAT</strong></span
        >
        <span *ngIf="order.urgency == 'ASAP'" class="cp-badge urgent-med"
          ><strong>ASAP</strong></span
        >
      </div>
      <div>
        <span *ngIf="order.category === 'completed'">
          Completed: &nbsp;{{ order.updatedAt | timezone: "DD-MM-yyyy, HH:mm" }}
          hrs
        </span>
        <span *ngIf="state.type === 'inactive'">
          Discontinued: &nbsp;{{ order.updatedAt | timezone: "DD-MM-yyyy, HH:mm" }}
          hrs
        </span>
        <span
          *ngIf="
            state.type !== 'inactive' &&
            state.type !== 'completed' &&
            order.endTime
          "
        >
          End: &nbsp;&nbsp;{{ order.endTime | timezone: "DD-MM-yyyy, HH:mm" }}
          hrs
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="cell-width-3">
      <span
        class="status-header"
        *ngIf="state.type !== 'pending' && state.type !== 'pta'"
        >Status</span
      >
    </th>
    <td mat-cell *matCellDef="let order">
      <ng-template [ngxPermissionsOnly]="['place_order']">
        <button
          mat-flat-button
          class="button-primary"
          *ngIf="state.type === 'pta'"
          (click)="orderToHospital(order); $event.stopPropagation()"
        >
          Order In Hospital
        </button>
      </ng-template>
      <div *ngIf="order.bedsideOrder">
        <ng-container
          *ngTemplateOutlet="
            orderState;
            context: {
              state: state,
              order: order,
              text: 'Ordered by bedside',
              isBedSide: order.bedsideOrder,
              tooltipText: 'Click to complete order',
              className: 'status-text bedside-order'
            }
          "
        >
        </ng-container>
      </div>

      <ng-template
        [ngxPermissionsOnly]="['sign_order']"
        *ngIf="!isDischargeTimeElapsed"
      >
        <div *ngIf="state.sign">
          <label
            (click)="signed(order)"
            [matTooltipDisabled]="!order.replaceOrderId"
            [matTooltip]="linkedOrderTooltip"
            placement="left"
            class="btn btn-sm mr-1 sign-label status-text order-action-yellow"
          >
            Sign
            <span *ngIf="patientName"
              >for
              {{
                patientName.length > 10
                  ? patientName.slice(0, 10) + "..."
                  : patientName
              }}</span
            >
          </label>
        </div>
      </ng-template>
      <span *ngIf="order.state == 'red' && !order.bedsideOrder">
        <ng-container
          *ngTemplateOutlet="
            orderState;
            context: {
              state: state,
              order: order,
              text: 'Not Communicated',
              tooltipText:
                'Click after order has been communicated to bedside nurse',
              className: 'status-text cp-badge-danger'
            }
          "
        >
        </ng-container>
      </span>
      <span *ngIf="order.state == 'yellow'">
        <ng-container
          *ngTemplateOutlet="
            orderState;
            context: {
              state: state,
              order: order,
              text: 'Communicated',
              tooltipText:
                'Click after order has been acted upon by bedside nurse',
              className: 'status-text order-action-yellow'
            }
          "
        >
        </ng-container>
      </span>
      <span *ngIf="order.state == 'green'">
        <ng-container
          *ngTemplateOutlet="
            orderState;
            context: {
              state: state,
              order: order,
              text: 'Acted upon',
              tooltipText: 'Click to complete order',
              className: 'status-text order-action-green'
            }
          "
        >
        </ng-container>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="viewDetails">
    <th mat-header-cell *matHeaderCellDef class="cell-width-2"></th>

    <td mat-cell *matCellDef="let order">
      <button
        mat-flat-button
        class="button-primary"
        (click)="clickDetailsBtn(order)"
      >
        View details
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit-discontinue">
    <th mat-header-cell *matHeaderCellDef class="cell-width-1"></th>

    <td mat-cell *matCellDef="let order" class="text-center">
      <ng-template [ngxPermissionsOnly]="['edit_order']">
        <span
          *ngIf="
            orders$ | async | canEditOrder: order._id:state.role:order.type;
            else disableEditIcon
          "
        >
          <span
            *ngIf="state.edit && !isDischargeTimeElapsed"
            class="edit-icon"
            matTooltip="Edit Order"
            (click)="modify(order); $event.stopPropagation()"
            [disabled]="editing$ | async"
          >
            <img src="assets/icons/edit.svg" />
          </span>
        </span>

        <ng-template #disableEditIcon>
          <span
            *ngIf="state.edit && !isDischargeTimeElapsed"
            class="edit-icon"
            matTooltip="A pended order is already present for this order"
          >
            <img src="assets/icons/edit_disabled.svg" />
          </span>
        </ng-template>

        <a
          *ngIf="state.revert && isDischargeTimeElapsed === false"
          class="p-2"
          matTooltip="Revert to Active orders"
          (click)="revert(order); $event.stopPropagation()"
        >
          <fa-icon [icon]="['fas', 'arrow-up']" size="lg"> </fa-icon>
        </a>
      </ng-template>

      <ng-template [ngxPermissionsOnly]="['discontinue_order']">
        <span
          *ngIf="
            orders$ | async | canEditOrder: order._id:state.role:order.type;
            else disableDiscontinueIcon
          "
        >
          <span
            *ngIf="(state.discard || state.delete) && !isDischargeTimeElapsed"
          >
            <img
              *ngIf="state.type !== 'pending' && state.type !== 'pta'"
              class="cp-cursor-pointer"
              src="assets/icons/cancel_rounded.svg"
              matTooltip="Discontinue order"
              (click)="
                openDialog(discontinueModal, order, 650);
                $event.stopPropagation()
              "
            />

            <img
              *ngIf="state.type === 'pending' || state.type === 'pta'"
              class="cp-cursor-pointer"
              src="assets/icons/delete.svg"
              matTooltip="Delete order"
              (click)="
                openDialog(discontinueModal, order, 650);
                $event.stopPropagation()
              "
            />
          </span>
        </span>

        <ng-template #disableDiscontinueIcon>
          <span
            *ngIf="(state.discard || state.delete) && !isDischargeTimeElapsed"
            class="discontinue-icon"
            matTooltip="A pended order is already present for this order"
          >
            <img src="assets/icons/cancel_disabled.svg" />
          </span>
        </ng-template>
      </ng-template>

      <ng-template [ngxPermissionsOnly]="['place_order']">
        <img
          src="assets/icons/undo.svg"
          class="cp-cursor-pointer"
          *ngIf="state.type === 'inactive' && !order.disableReorder"
          matTooltip="Reorder"
          (click)="reOrderOrder(order); $event.stopPropagation()"
        />
        <img
          src="assets/icons/not_available.svg"
          *ngIf="state.type === 'inactive' && order.disableReorder"
          matTooltip="No longer available"
        />
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="orderTableColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: orderTableColumns"
    [class.order-row-selected]="
      checkIfOrderExistInProtocol(row, activeProtocols)
    "
    (contextmenu)="onContextMenu($event, row)"
  ></tr>
</table> -->

<!--<div
class="m-1 order-row"
(contextmenu)="onContextMenu($event, order)"
[class.order-row__selected]="
  checkIfOrderExistInProtocol(order, activeProtocols)
"
>
<ng-container *ngIf="{ isCollapsed: true } as status">
     
    <div
      [class.order-row__hide]="
        activeProtocols &&
        activeProtocols.length > 0 &&
        !checkIfOrderExistInProtocol(order, activeProtocols)
      "
    ></div>
</ng-container>
</div>
-->

<ng-template #discontinueModal let-modal>
  <div class="modal-popup">
    <div class="header">
      <img src="assets/icons/warning_24px.svg" />
      <h2 class="modal-title" id="modal-basic-title">
        <b>Attention</b>
      </h2>
    </div>
    <form [formGroup]="discontinueForm" (ngSubmit)="disContinue()">
      <div class="body">
        <div class="form-row">
          <div
            class="mb-3 ml-2"
            *ngIf="state.type === 'pending' || state.type === 'pta'"
          >
            Do you want to delete this order?<br /><br />
            Please select the reason(s) for deletion.
          </div>
          <div
            class="mb-3 ml-2"
            *ngIf="
              state.type !== 'pending' &&
              state.type !== 'pta' &&
              !!ngxPermissionsService.getPermission('discontinue_order') &&
              !!!ngxPermissionsService.getPermission(
                'mark_to_discontinue_order'
              )
            "
          >
            Do you want to discontinue this order?<br /><br />
            Please select the reason(s) for discontinuation.
          </div>
          <div
            class="mb-3 ml-2"
            *ngIf="
              state.type !== 'pending' &&
              state.type !== 'pta' &&
              !!ngxPermissionsService.getPermission('mark_to_discontinue_order')
            "
          >
            Do you want to mark this order for discontinuation?<br /><br />
            Please select the reason(s) below.
          </div>
          <div class="reasons">
            <div
              (click)="onOptionClick(reason)"
              *ngFor="let reason of disContinueReason"
              class="button-secondary"
              [class.active]="selectedDisContinueReason.includes(reason)"
            >
              {{ reason }}
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedDisContinueReason.includes('Other')"
          class="form-row"
        >
          <div
            class="form-group col-md-12 pt-3 d-md-flex flex-md-column justify-content-md-end"
          >
            <label>Reason</label>
            <textarea
              id="reasonForDiscontinue"
              class="cp-textarea"
              formControlName="reasonForDiscontinue"
              rows="2"
            ></textarea>
          </div>
        </div>
        <div *ngIf="selectedOrder?.labId" class="lab-discontinue cp-mt-1">
          * Please note your order is currently being processed. Results will be
          reflected in the labs once it is available.
        </div>
      </div>
      <div class="footer button-group-horizontal">
        <button
          matDialogClose
          mat-flat-button
          class="button-secondary"
          type="button"
          (click)="onClose()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          matDialogClose
          class="button-error"
          type="submit"
        >
          {{
            state.type === "pending" || state.type === "pta"
              ? "Delete"
              : !!ngxPermissionsService.getPermission("discontinue_order") &&
                !!!ngxPermissionsService.getPermission(
                  "mark_to_discontinue_order"
                )
              ? "Discontinue"
              : "Mark"
          }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #completeOrderModal let-modal>
  <div class="modal-popup">
    <div class="header">
      <img src="assets/icons/warning_24px.svg" />
      <h2 class="modal-title" id="modal-basic-title"><b>Attention</b></h2>
    </div>
    <div class="body">Do you want to complete this order?</div>
    <div class="footer">
      <button matDialogClose mat-flat-button class="button-secondary">
        Cancel
      </button>
      <button
        matDialogClose
        mat-flat-button
        class="button-primary"
        (click)="completeOrder()"
      >
        Complete
      </button>
    </div>
  </div>
</ng-template>

<ng-template
  let-state="state"
  let-order="order"
  let-text="text"
  let-isBedSide="isBedSide"
  let-tooltip="tooltipText"
  let-stateClass="className"
  #orderState
>
  <div class="communicate-order">
    <ng-template [ngxPermissionsOnly]="['communicate_order']">
      <img
        src="assets/icons/arrow_left.svg"
        *ngIf="state?.communication && isDischargeTimeElapsed === false"
        [ngClass]="{
          'cp-cursor-pointer': order.state === 'green',
          invisible:
            order?.frequency?.fType != 'every' || order.state != 'green'
        }"
        (click)="previousAction(order); $event.stopPropagation()"
      />
    </ng-template>

    <!-- Note: 'status-text-ml' class name is given to allign it with the status column -->
    <span
      *ngIf="state.type != 'pending' && state.type != 'pta'"
      [ngClass]="{ 'status-text-ml': isStatusMargin }"
      [className]="stateClass"
      >{{ text }}
    </span>

    <ng-template [ngxPermissionsOnly]="['communicate_order']">
      <img
        src="assets/icons/arrow_right.svg"
        *ngIf="state.communication && isDischargeTimeElapsed === false"
        (click)="
          nextAction(order, completeOrderModal, isBedSide);
          $event.stopPropagation()
        "
        class="cp-cursor-pointer"
      />

      <span
        class="cp-cursor-pointer display-flex center"
        popoverTrigger="hover"
        matTooltipPosition="above"
        matTooltip="{{ tooltip }}"
        *ngIf="state.communication && isDischargeTimeElapsed === false"
        ><mat-icon color="primary" inline="true">info</mat-icon></span
      >
    </ng-template>
  </div>
</ng-template>

<ng-template #timeZoneLabel>
  <cp-timezone-label
    *ngIf="currentPatient?.hospitalInfo?.timeZoneDetail"
    [timeZoneDetail]="currentPatient?.hospitalInfo?.timeZoneDetail"
  ></cp-timezone-label>
</ng-template>
