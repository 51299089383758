<div class="chat-module">
  <div class="chat-view-window" id="chat-area">
    <ng-container *ngFor="let chat of chatMessages; index as i">
      <div
        class="chat-message"
        id="chat-no-{{ i }}"
        [ngClass]="{
          self: user.email == chat.senderEmail,
          doc_message: chat.senderRole == 'Doctor',
          nurse_message: chat.senderRole == 'Nurse',
          system_message: chat.senderRole == 'System'
        }"
      >
        <div *ngIf="user.email !== chat.senderEmail" class="avatar-icon">
          <app-avatar [avatarParams]="avatarParamsArr[i]"></app-avatar>
        </div>
        <div
          class="chat-msg-holder"
          [ngClass]="{
            'primary-bg-light': user.email === chat.senderEmail,
            strikedOff: chat.isDeleted
          }"
        >
          <div class="chat-name">
            <b
              *ngIf="user.email !== chat.senderEmail"
              [ngStyle]="{
                color:
                  chat.senderName == 'Cloudphysician Radar' ? '#3271DA' : ''
              }"
              >{{ chat.senderName }}
              {{ chat.senderRole == CCA ? "(CCA)" : "" }}</b
            >
            <b *ngIf="user.email === chat.senderEmail">You</b>
            <span class="chat-time"
              >{{
                chat.timestamp
                  | timezone : "DD/MM/YY, HH:mm" : timeZoneDetail?.name
              }}
            </span>
            <cp-timezone-label
              *ngIf="timeZoneDetail?.name"
              [timeZoneDetail]="timeZoneDetail"
            ></cp-timezone-label>
          </div>
          <cp-reply-message
            *ngIf="!chat.isDeleted && chat.refMessage?.senderName"
            [refMessage]="getChatData(chat?.refMessage?.timestamp)"
            (click)="goToMessageReplied(chat.refMessage, i)"
          >
          </cp-reply-message>
          <div
            class="chat-text mt"
            [ngClass]="{ 'chat-text--video': isVideoContainer(chat) }"
          >
            <div *ngIf="chat.attachmentLink?.key" class="chat_image mb-1">
              <ng-container
                *ngIf="!chat.imageLoaded"
                [ngSwitch]="getFileType(chat.attachmentLink?.fileType)"
              >
                <img
                  alt="image icon"
                  matRipple
                  class="photo-icon cp-cursor-pointer"
                  src="assets/icons/photo.svg"
                  *ngSwitchCase="fileTypes.IMAGE"
                  (click)="downloadImage(chat)"
                  matTooltip="View image"
                />
                <img
                  matRipple
                  alt="pdf icon"
                  src="assets/icons/pdf-file.svg"
                  *ngSwitchCase="fileTypes.PDF"
                  class="pdf-file-icon cp-cursor-pointer"
                  matTooltip="View pdf"
                  (click)="downloadImage(chat)"
                />
                <mat-icon
                  matTooltip="View video"
                  *ngSwitchCase="fileTypes.VIDEO"
                  (click)="downloadImage(chat)"
                  class="cp-cursor-pointer cp-icon"
                  >videocam</mat-icon
                >
              </ng-container>
              <ng-container
                *ngIf="chat.imageLoaded"
                [ngSwitch]="getFileType(chat.attachmentLink?.fileType)"
              >
                <ng-container *ngSwitchCase="fileTypes.PDF">
                  <iframe
                    style="width: 15rem; height: 10rem"
                    [attr.src]="chat.image"
                    type="application/pdf"
                  ></iframe>
                </ng-container>
                <ng-container *ngSwitchCase="fileTypes.IMAGE">
                  <img
                    matRipple
                    class="attached-image mr-2 cp-cursor-pointer"
                    [src]="chat.image"
                    alt="Attached Image"
                    (click)="openZoomedView(chat)"
                    matTooltip="Zoom in image"
                  />
                </ng-container>
                <ng-container *ngSwitchCase="fileTypes.VIDEO">
                  <cp-player
                    [src]="chat.image"
                    [fileType]="chat.attachmentLink?.fileType"
                    class="chat_image__video"
                    (thumbnail)="videoSrc = chat.image; closeZoomedView()"
                    videoType="thumbnail"
                  ></cp-player>
                </ng-container>
                <div
                  class="chat-file-actions mt"
                  *ngIf="
                    getFileType(chat.attachmentLink?.fileType) !=
                    fileTypes.VIDEO
                  "
                >
                  <img
                    *ngIf="chat.attachmentLink?.fileType == 'application/pdf'"
                    matRipple
                    src="assets/icons/pdf-file.svg"
                    class="pdf-file-icon mr-2 cp-cursor-pointer"
                    matTooltip="Zoom in pdf"
                    (click)="openZoomedView(chat)"
                  />
                  <ng-container *ngIf="!isDischargeTimeElapsed">
                    <img
                      matRipple
                      src="assets/icons/doc_add.svg"
                      (click)="addToDocs(chat)"
                      matTooltip="Add to Labs/Scans"
                      class="add_to_docs cp-cursor-pointer"
                      *ngxPermissionsOnly="['create_lab']"
                    />
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div
              class="chat-msg"
              [ngClass]="{
                'chat-msg--video': isVideoContainer(chat)
              }"
            >
              <cp-readmore
                [maxCharectors]="200"
                [text]="chat.message"
              ></cp-readmore>
            </div>
            <div
              class="chat-info text-right"
              *ngIf="chat.attachmentLink?.key == undefined"
            >
              <div *ngIf="!isDischargeTimeElapsed">
                <img
                  (click)="replyToThisMessage(chat)"
                  matRipple
                  class="replyBtn mr-1 cp-cursor-pointer"
                  matTooltip="Reply"
                  src="assets/icons/reply.svg"
                />
              </div>
              <div
                *ngIf="
                  !isDischargeTimeElapsed &&
                  chat.senderEmail === user.email &&
                  !chat.isDeleted
                "
              >
                <img
                  matRipple
                  (click)="openStrikeOffModal(true, chat)"
                  src="assets/icons/delete_outline.svg"
                  class="mr-1 cp-cursor-pointer"
                  matTooltip="Strike - off"
                />
              </div>
              <div>
                <img
                  matRipple
                  (click)="openReadReciepts(chat)"
                  src="assets/icons/info_outline.svg"
                  class="cp-cursor-pointer mr-1"
                  matTooltip="Info"
                />

                <!-- <mat-icon color="primary" *ngIf="chat.read">info </mat-icon> -->
              </div>
            </div>
          </div>
          <div class="chat-text" *ngIf="chat.attachmentLink?.key">
            <div
              class="chat-status"
              *ngIf="
                (!chat.addedToDocuments ||
                  chat.addedToDocuments == undefined) &&
                chat.documentAdded !== true &&
                getFileType(chat.attachmentLink?.fileType) != fileTypes.VIDEO
              "
            >
              <span Class="status-icon mr-2"
                ><mat-icon [ngStyle]="{ color: 'rgb(137 129 129 / 86%)' }"
                  >history</mat-icon
                ></span
              ><span class="status-text">Pending</span>
            </div>
            <div
              class="chat-status"
              *ngIf="chat.addedToDocuments || chat.documentAdded == true"
            >
              <span Class="status-icon mr-2"
                ><mat-icon [ngStyle]="{ color: '#28A677' }"
                  >done</mat-icon
                ></span
              ><span class="status-text">Added to lab/scan</span>
            </div>
            <div class="chat-info text-right">
              <div *ngIf="!isDischargeTimeElapsed">
                <img
                  (click)="replyToThisMessage(chat)"
                  matRipple
                  class="replyBtn mr-1 cp-cursor-pointer"
                  matTooltip="Reply"
                  src="assets/icons/reply.svg"
                />
              </div>
              <div
                *ngIf="
                  !isDischargeTimeElapsed &&
                  chat.senderEmail === user.email &&
                  !chat.isDeleted
                "
              >
                <img
                  matRipple
                  (click)="openStrikeOffModal(true, chat)"
                  src="assets/icons/delete_outline.svg"
                  class="mr-1 cp-cursor-pointer"
                  matTooltip="Strike - off"
                />
              </div>
              <div>
                <img
                  matRipple
                  (click)="openReadReciepts(chat)"
                  src="assets/icons/info_outline.svg"
                  class="cp-cursor-pointer mr-1"
                  matTooltip="Info"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="unread_message_point"
        class="new_messages"
        *ngIf="chat['new'] && showUnreadMessage"
      >
        <div>Unread Messages</div>
      </div>
    </ng-container>
    <div class="no_messages" *ngIf="!chatMessages?.length">
      No messages in this thread
    </div>
  </div>

  <div class="read-only-footer" *ngIf="isDischargeTimeElapsed">
    <div class="message">
      This patient has been discharged. You can no longer send messages to this
      chat.
    </div>
  </div>

  <div class="chat-enter-window" *ngIf="!isDischargeTimeElapsed">
    <div *ngIf="chatType === 'patient-chart'" class="chat-buttons">
      <div class="attach-btn">
        <cp-file-upload
          (uploadStart)="isViewAttchOpen = true; attchObj = $event"
          (uploadedData)="onFileUpload($event)"
          (fileValidationError)="fileValidationError = $event"
          [isDischargeTimeElapsed]="isDischargeTimeElapsed"
        ></cp-file-upload>
      </div>
    </div>
    <div class="chat-area">
      <cp-reply-message
        *ngIf="refMessage"
        [refMessage]="refMessage"
        [isClose]="true"
        (close)="cancelReply()"
      ></cp-reply-message>
      <textarea
        #chatEnterArea
        maxlength="250"
        name="chat-area"
        id="chatMessage"
        [(ngModel)]="chatMessage"
        (keydown)="detectKey($event)"
        placeholder="Type a message"
      ></textarea>
    </div>
    <div class="chat-buttons">
      <div
        class="send-btn"
        (click)="
          chatType == 'patient-chart' ? submitMessagePC() : submitMessageUL()
        "
      >
        <img
          matRipple
          *ngIf="chatMessage"
          src="assets/icons/send.svg"
          class="cp-cursor-pointer"
        />
        <img *ngIf="!chatMessage" src="assets/icons/send_disabled.svg" />
      </div>
    </div>
  </div>

  <div class="read_reciept_wrapper" *ngIf="isRecieptOpen">
    <div class="reciept_container">
      <div class="m_header">
        <div class="m_header_text">Read receipts</div>
        <div
          class="close-icon cp-cursor-pointer"
          matRipple
          (click)="isRecieptOpen = false"
        >
          <img src="assets/icons/close.svg" />
        </div>
      </div>
      <div class="the_message">
        <div class="message-title">Message</div>
        <div class="message-body">
          <div *ngIf="user.email != clickedMessage.senderEmail" class="name">
            <b>{{ clickedMessage.senderName }}</b>
          </div>
          <div class="msg">{{ clickedMessage.message }}</div>
        </div>
      </div>
      <div *ngIf="clickedMessage.readBy.length" class="read_by_list">
        <div class="readby-title">Read By</div>

        <div class="readby-name">
          <ng-container *ngFor="let readBy of clickedMessage.readBy">
            <div
              *ngIf="
                readBy.email != user.email &&
                readBy.email != clickedMessage.senderEmail
              "
              class="float-left"
            >
              {{ readBy.name }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="delete_message_wrapper" *ngIf="isStrikeOffOpen">
    <div class="delete_message_container">
      <div class="msg-header">
        <div class="info-icon"><img src="assets/icons/warning_24px.svg" /></div>
        <div class="title"><b>Attention</b></div>
      </div>
      <div class="message">
        Do you want to delete this message? This action cannot be undone.
      </div>
      <div class="button_holder button-group-horizontal">
        <button
          mat-flat-button
          class="button-secondary"
          (click)="openStrikeOffModal(false)"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="button-error"
          (click)="strikeOffMessage()"
        >
          Delete
        </button>
      </div>
      <img
        matRipple
        src="assets/icons/close.svg"
        class="cancel_delete cp-cursor-pointer"
        matTooltip="Cancel"
        (click)="openStrikeOffModal(false)()"
      />
    </div>
  </div>

  <div class="image_upload_wrapper" *ngIf="isViewAttchOpen">
    <div class="holder">
      <div class="m_header">
        <div [ngSwitch]="getFileType(attchObj?.type)">
          <ng-container *ngSwitchCase="fileTypes.PDF">
            PDF attachment
          </ng-container>
          <ng-container *ngSwitchCase="fileTypes.IMAGE">
            Image attachment
          </ng-container>
          <ng-container *ngSwitchCase="fileTypes.VIDEO">
            Video attachment
          </ng-container>
        </div>
        <div class="close_popup" (click)="clearAttachments()">
          <img
            matRipple
            src="assets/icons/close.svg"
            class="cp-cursor-pointer"
          />
        </div>
      </div>
      <div class="invalid_file" *ngIf="fileValidationError">
        {{ fileValidationError }}
      </div>
      <div *ngIf="attchObj" class="m_body">
        <div class="img-container text-center">
          <ng-container
            *ngIf="!attchObj['isFileLoading']; else loadingFile"
            [ngSwitch]="getFileType(attchObj['type'])"
          >
            <ng-container *ngIf="attchObj['fileUrl']">
              <iframe
                *ngSwitchCase="fileTypes.PDF"
                style="width: 100%; height: 100%"
                [attr.src]="attchObj['fileUrl']"
                type="application/pdf"
              ></iframe>
              <ng-container *ngSwitchCase="fileTypes.IMAGE">
                <div class="image_holder">
                  <img
                    class="mb-1"
                    [src]="attchObj['fileUrl']"
                    *ngIf="attchObj['fileUrl']"
                  />
                </div>
                <!-- Rotate the image -->
                <div
                  matTooltip="Rotate Image"
                  (click)="rotateBase64Image90deg(attchObj['fileUrl'], true, i)"
                >
                  <img
                    matRipple
                    src="assets/icons/refresh.svg"
                    class="cp-cursor-pointer"
                  />
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="fileTypes.VIDEO">
                <cp-player
                  *ngIf="attchObj['fileUrl']"
                  [src]="attchObj['fileUrl']"
                  class="img-container__video-preview"
                  videoType="preview"
                ></cp-player>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div
          class="chat-enter-window"
          *ngIf="!(attchObj['isFileLoading'] || fileValidationError)"
        >
          <div class="chat-area">
            <textarea
              maxlength="250"
              name=""
              id="chatMessage"
              [(ngModel)]="chatMessage"
              (keydown)="detectKey($event)"
              placeholder="Type a message"
            ></textarea>
          </div>
          <div class="chat-buttons">
            <div (click)="chatMessage ? submitMessagePC() : ''">
              <ng-container *ngIf="!isUploadLoader; else loader">
                <img
                  matRipple
                  *ngIf="chatMessage"
                  src="assets/icons/send.svg"
                  class="cp-cursor-pointer"
                />
                <img
                  *ngIf="!chatMessage"
                  src="assets/icons/send_disabled.svg"
                />
              </ng-container>
              <ng-template #loader>
                <mat-spinner [diameter]="30"></mat-spinner>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <ng-template #loadingFile>
        <div class="display-flex align-items-center">
          <mat-spinner [diameter]="30"></mat-spinner>
          <span class="ml"> Please wait</span>
        </div>
      </ng-template>
    </div>
  </div>

  <app-overlay
    #overlayComp
    [hasbackdrop]="false"
    [panelClass]="'labsandscans-overlay-pannel'"
    [backdropCloseOnClick]="false"
  >
    <section class="cp-carousel-wrapper">
      <div class="title">
        <span>Zoomed view</span>
        <span>
          <img
            class="cp-cursor-pointer"
            src="assets/icons/close.svg"
            alt="close"
            (click)="closeZoomedView()"
          />
        </span>
      </div>

      <div class="content">
        <app-carousel [slides]="zoomedChat"></app-carousel>
      </div>
    </section>
  </app-overlay>

  <section class="videoplayer-popup cp-carousel-wrapper" *ngIf="videoSrc">
    <mat-icon
      class="videoplayer-popup__close cp-cursor-pointer"
      (click)="videoSrc = null"
      >close</mat-icon
    >
    <cp-player [src]="videoSrc"></cp-player>
  </section>
</div>
