import { createReducer, on, Action } from "@ngrx/store";

import * as vitalActions from "../actions/vitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  loading: boolean;
  error: any;
  loadType: string;
  showSuccess: boolean;
}

const initialState: State = {
  loading: false,
  error: null,
  loadType: null,
  showSuccess: false,
};

const vitalFormReducer = createReducer(
  initialState,
  on(vitalActions.submitForm, (state, { loadType }) => ({
    ...state,
    loading: true,
    loadType: loadType,
  })),
  on(vitalActions.submitSuccess, (state) => ({
    ...initialState,
    showSuccess: true,
  })),
  on(vitalActions.hideSuccess, (state) => ({
    ...initialState,
    showSuccess: false,
  })),
  on(vitalActions.submitFail, (state, { error }) => ({
    ...initialState,
    error: error,
  })),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return vitalFormReducer(state, action);
}
