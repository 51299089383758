import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import {
  faComments,
  faFileMedicalAlt,
} from "@fortawesome/free-solid-svg-icons";
import * as chatActions from "src/app/patient/chat/store/actions/chat.actions";
import * as fromChatIndex from "src/app/patient/chat/store/reducers";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as fromOrder from "../../store/reducers/order";
// import { Socket } from 'ngx-socket-io';
import { MatTabChangeEvent, MatTabNav } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { NoteService } from "@notes-view/services/note.service";
import { CTAType } from "@notes/models/notes.model";
import { NoteFormsService } from "@notes/services/note-forms.service";
import * as fromNoteStore from "@notes/store";
import { NgxPermissionsService } from "ngx-permissions";
import { NoteInputComponent } from "src/app/note-view-module/note-input/note-input.component";
import { SiblingService } from "src/app/services/sibling.service";
import * as fromUserReducer from "src/app/store/reducers/user";
import { socket } from "../../config/socket";
import { InputPopupService } from "../../shared/input-popup.service";
import * as OrderActions from "../../store/actions/order/order-main.actions";

@Component({
  selector: "app-input-popup",
  templateUrl: "./input-popup.component.html",
  styleUrls: ["./input-popup.component.scss"],
})
export class InputPopupComponent implements OnInit, OnDestroy {
  @ViewChild("matTabNav") matTabNav: MatTabNav;
  @Input("isDischargeTimeElapsed") isDischargeTimeElapsed: boolean;
  @ViewChild("popupBoxCont") popupBoxCont: ElementRef;

  @HostListener("keydown", ["$event"]) onKeyDown(e) {
    if (e.ctrlKey && e.shiftKey && e.keyCode == 191) {
      this.showPopup = !this.showPopup;
    }
  }

  /**
   * Declaration
   */
  private unsubscribe$: Subject<any> = new Subject<any>();
  public $orderInput: Observable<boolean>;
  public CPMRN;
  public encounters;
  public currentUser;
  public showPopup = false;
  public notesValue;
  public draftedNewNote;
  public currPatient;
  public selectedIndex: number = 0;
  public chatData = [];

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  public noteCTA$ = this._noteStore.pipe(
    select(fromNoteStore.getCTA),
    takeUntil(this.unsubscribe$)
  );

  public chat$ = this.store.pipe(
    select(fromChatIndex.getChatMessages),
    takeUntil(this.unsubscribe$)
  );

  public chatNoti$ = this.store.pipe(
    select(fromChatIndex.getNotificationStatus),
    takeUntil(this.unsubscribe$)
  );
  public users$ = this.store.pipe(
    select(fromUserReducer.getUser),
    takeUntil(this.unsubscribe$)
  );

  public showNotification: boolean = false;

  faMedicalAlt = faFileMedicalAlt;
  faClipboard = faClipboard;
  faComments = faComments;

  popupTitle = "Orders, Notes, Chat & Roundaᴙ";
  tabIndexMap = {};

  @ViewChild(NoteInputComponent) noteForm: NoteInputComponent;

  constructor(
    private _siblingService: SiblingService,
    public store: Store<{}>,
    private _inputPopupService: InputPopupService,
    private ngxPermissionsService: NgxPermissionsService,
    private router: Router,
    private _notesFormsService: NoteFormsService,
    private _noteService: NoteService,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {
    this.$orderInput = store.pipe(
      select(fromOrder.getInputTabActive),
      takeUntil(this.unsubscribe$)
    );
  }

  /**
   * Getting CPMRN
   * Subscribing for notifyNotesGetUpdate which will provide selected note object for updating
   */
  ngOnInit() {
    this.patientHeader$.subscribe((patient) => {
      if (patient && patient.CPMRN) {
        this.currPatient = patient;
        this.CPMRN = this.currPatient.CPMRN;
        this.encounters = this.currPatient.encounters;
      }
    });

    this._noteService.openPopUpTrigger$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          return this.openPopup("notes");
        }
      });

    this.noteCTA$.subscribe((data) => {
      if (data.cta == CTAType.ActionComplete)
        return this.collapsePopup("head", false);
    });

    this.users$.subscribe((data) => (this.currentUser = data.currentUser));

    this.$orderInput.subscribe((val) => {
      if (val) {
        this.showPopup = true;
        if (this.popupBoxCont)
          this.popupBoxCont.nativeElement.style.display = "block";

        setTimeout(() => {
          this.matTabNav.selectedIndex = 0;
        }, 10);
      } else {
        this.showPopup = false;
        if (this.popupBoxCont)
          this.popupBoxCont.nativeElement.style.display = "none";
      }
    });

    // subscribe to chat
    this.chat$.subscribe((data) => {
      if (data.length) {
        this.chatData = data;
      }
    });

    // listen to socket
    socket?.on("chat-hide-notification", (data) => {
      if (
        data?.readReciept?.email == this.currentUser.email &&
        data?.CPMRN == this.currPatient.CPMRN &&
        data?.encounters == this.currPatient.encounters
      ) {
        this.store.dispatch(chatActions.hideNotification());
      }
    });

    // open popup if something arrives in observable
    this._inputPopupService.openPopup$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((popupId) => {
        this.openPopup(popupId);
      });

    this.popupTitle = this.getPopupTitle();

    // listen to new change
    this.chatNoti$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      setTimeout(() => {
        this.showNotification = data;
      }, 0);
    });

    this.setPopupIndexMap();
  }

  public isInitialState$ = this._noteStore.pipe(
    select(fromNoteStore.isInitialState)
  );

  ngAfterViewInit() {
    this.popupBoxCont.nativeElement.style.display = "none";
  }
  /**
   * To control the tabs in popup window
   */
  // @ViewChild('tabs')

  /**
   * To unsubscribe all the Behaviour subjectes
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    socket?.removeAllListeners("chat-hide-notification");
  }

  /**
   * Storing indices to select a mat tab when their respective icon is clicked
   */
  setPopupIndexMap() {
    let index = 0;

    if (this.ngxPermissionsService.getPermission("place_order"))
      this.tabIndexMap["order"] = index++;

    if (this.ngxPermissionsService.getPermission("get_chat"))
      this.tabIndexMap["chat"] = index++;

    if (this.ngxPermissionsService.getPermission("create_note"))
      this.tabIndexMap["notes"] = index;
  }

  /**
   * This function is to load which tab should be loaded(orders/notes)
   * @param val will contains the id of the tab to be loaded while opening the popup
   */
  loadTab() {
    this.showPopup = true;
    // this.tabs.select(val);
  }
  openPopup(tabLabel) {
    if (tabLabel == "order") {
      this._siblingService.sendResetFlag("true");
    }
    this.selectedIndex = this.tabIndexMap[tabLabel];
    this.showPopup = true;
    this.popupBoxCont.nativeElement.style.display = "block";

    if (tabLabel == "notes") {
      this._noteService.buttonPopUpTrigger(true);
    }

    if (tabLabel === "chat" && this.selectedIndex === 0) {
      this.store.dispatch(chatActions.openChatTab());
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (!this.showPopup) return;

    if (tabChangeEvent.index === this.tabIndexMap["notes"]) {
      this._noteService.buttonPopUpTrigger(true);
    }

    if (tabChangeEvent.index === this.tabIndexMap["chat"]) {
      this.store.dispatch(chatActions.openChatTab());
    } else {
      this.store.dispatch(chatActions.closeChatTab());
    }
  }

  listenToChange(event) {
    this.showPopup = event;
    this.popupBoxCont.nativeElement.style.display = event ? "block" : "none";
  }

  collapsePopup(origin = "btn", triggerClose = true) {
    this.store.dispatch(chatActions.closeChatTab());
    // this._noteStore.dispatch(fromNoteStore.setCTA(null));

    if (origin === "head") {
      this.showPopup = false;
      this.selectedIndex = null;
      this.popupBoxCont.nativeElement.style.display = "none";
      this.store.dispatch(new OrderActions.CloseInputTab());
      if (triggerClose) {
        this._notesFormsService.popUpCloseTrigger(true);
      }
    }
  }

  getPopupTitle(): string {
    if (!this.ngxPermissionsService.getPermission("read_note")) {
      return "Chat";
    }

    if (
      this.ngxPermissionsService.getPermission("read_note") &&
      !this.ngxPermissionsService.getPermission("place_order")
    ) {
      return "Notes & Chat";
    }

    return "Orders, Notes, Chat & Roundaᴙ";
  }

  /** To add new patient*/
  addNewPatient() {
    this.router.navigate(["admitPatient"]);
  }

  getPatientAge() {
    const ageObj = this.currPatient?.age;

    if (!ageObj) return "";

    return ageObj.year
      ? `${ageObj.year} yr`
      : ageObj.month
      ? `${ageObj.month} mos`
      : ageObj.day
      ? `${ageObj.day} days`
      : ageObj.hour
      ? `${ageObj.hour} hrs`
      : "";
  }
}
