import { Component, Input } from "@angular/core";
import { DatePipe, TitleCasePipe } from "@angular/common";

import * as moment from "moment";
import { Patient, PatientType } from "src/app/models/patient";
import { IcuDaysPipe } from "@shared-modules/pipes/icu-days-pipe/icu-days.pipe";

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");
import { jsPDFDocument } from "jspdf-autotable";
import { UtilService } from "src/app/services/util.service";
import { displayAge } from "src/app/support-functions/calculateAge";
import { TimezoneService } from "src/app/services/timezone.service";
import { TimezonePipe } from "@shared-modules/pipes/timezone-pipe/timezone.pipe";
import { TimeZoneDetails } from "src/app/models/hospital";
const ADULT_UNIT = "ml";
const NICU_PED_UNIT = "ml/kg/hr";
@Component({
  selector: "app-io-print",
  templateUrl: "./io-print.component.html",
  styleUrls: ["./io-print.component.scss"],
})
export class IoPrintComponent {
  imgData = null;
  titlePipe = new TitleCasePipe();
  datepipe = new DatePipe("en-US");
  timeZone = new TimezonePipe();
  currentDate = this.timeZone.transform(new Date().toString(), "dd-MM-yyyy");
  public timeZoneDetail: TimeZoneDetails;
  _currentPatient: Patient;
  @Input() set currentPatient(value: Patient) {
    this._currentPatient = value;
    if (value?.hospitalLogo) {
      this.utils.getDataUri(value?.hospitalLogo, (info) => {
        this.imgData = info;
      });
    }
  }
  get currentPatient(): Patient {
    return this._currentPatient;
  }

  @Input() toDate?: string;
  @Input() fromDate?: string;
  @Input() disabled?: boolean;
  @Input() dateRangeValue?: string;
  @Input() report?: {
    avgUrine?: number;
    netReport?: number;
    intakeReport?: number;
    outputReport?: number;
  };

  constructor(private icuDaysPipe: IcuDaysPipe, private utils: UtilService) {}

  setPdfFileNameAndHeader(doc: jsPDFDocument): jsPDFDocument {
    // PDF file name
    doc.setProperties({
      title: `${this.currentPatient.name} ${this.currentPatient.lastName}_${this.currentPatient.MRN}_${this.currentDate}`,
      author: "Cloudphysician",
      creator: "Cloudphysician",
    });

    // File header
    doc.autoTable({
      body: [
        [
          {
            content: "I/O Report",
            styles: {
              halign: "center",
              fontSize: 14,
              fontStyle: "bold",
              textColor: "#2B2A3A",
              fillColor: [255, 255, 255],
            },
          },
        ],
      ],
    });

    return doc;
  }

  setPatientInfo(doc: jsPDFDocument, topY: number): jsPDFDocument {
    // Patient Info Table
    function getRoundedValue(value) {
      return Math.round(value * 100) / 100;
    }

    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      head: [["Patient information"]],
      styles: { fontSize: 10 },
    });
    this.timeZoneDetail =
      this.currentPatient?.timeZoneDetail ||
      this.currentPatient?.hospitalInfo?.timeZoneDetail;
    const noOfIcuDays = this.currentPatient.ICUAdmitDate
      ? this.icuDaysPipe.transform(
          this.currentPatient.ICUAdmitDate,
          !this.currentPatient?.isCurrentlyAdmitted &&
            this.currentPatient?.ICUDischargeDate
        )
      : null;
    doc.autoTable({
      styles: { fontSize: 10 },
      startY: topY + 8,
      columnStyles: {
        0: { cellWidth: 33 },
        1: { cellWidth: 58 },
        2: { cellWidth: 33 },
        3: { cellWidth: 58 },
      },
      body: [
        [
          "Name: ",
          this.currentPatient["lastName"]
            ? `${this.currentPatient["name"]} ${this.currentPatient["lastName"]}`
            : `${this.currentPatient["name"]}`,
          "MRN: ",
          `${this.currentPatient["MRN"] || "-"}`,
        ],
        [
          "Age / Gender:",
          `${this.getPatientAge()} / ${this.currentPatient["sex"] || "-"}`,
          "Unit / Bed no: ",
          `${this.currentPatient["unitName"] || "-"} / ${
            this.currentPatient["bedNo"] || "-"
          }`,
        ],
        [
          "Height / Weight: ",
          `${
            this.currentPatient["height"]
              ? getRoundedValue(this.currentPatient.height) + "cm"
              : "-"
          } / ${
            this.currentPatient.weight
              ? getRoundedValue(this.currentPatient.weight) + "kg"
              : "-"
          }`,
          "Admit date: ",
          `${
            this.currentPatient["ICUAdmitDate"]
              ? this.timeZone.transform(
                  this.currentPatient["ICUAdmitDate"].toString(),
                  "MMM D Y, HH:mm"
                ) +
                " " +
                `(${this.timeZoneDetail?.abbreviation})`
              : "-"
          }`,
        ],
        [
          "Blood group: ",
          `${this.currentPatient["bloodGroup"] || "-"}`,
          "BMI: ",
          `${
            this.currentPatient?.BMI > 0
              ? this.currentPatient?.BMI?.toFixed(2)
              : "-"
          }`,
        ],
        [
          "Allergies: ",
          `${
            (this.currentPatient["allergies"] &&
              this.currentPatient["allergies"].join(", ")) ||
            "-"
          }`,
          "No of days in ICU: ",
          `${noOfIcuDays || "-"}`,
        ],
      ],
    });

    // Setting image header
    this.utils.setImageHeader(doc, this.imgData);

    return doc;
  }

  setReportHeader(doc: jsPDFDocument, topY: number): jsPDFDocument {
    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      head: [["Start/end time"]],
      styles: { fontSize: 10 },
    });

    const timeFormate = "DD-MM-yyyy HH:mm";

    function getDurationForDateRange(toDate, fromDate) {
      const hour = moment(new Date(toDate))
        .diff(moment(new Date(fromDate)), "hours", true)
        .toFixed(2);
      return `${hour}hrs`;
    }
    const formattedDuration = moment().subtract(this.dateRangeValue, "h");
    doc.autoTable({
      styles: { fontSize: 10 },
      startY: topY + 8,
      columnStyles:
        this.dateRangeValue == "date_range"
          ? {
              0: { cellWidth: 33 },
              1: { cellWidth: 58 },
              2: { cellWidth: 33 },
              3: { cellWidth: 58 },
            }
          : {
              0: { cellWidth: 33 },
            },
      body:
        this.dateRangeValue == "date_range"
          ? [
              [
                "From: ",
                `${this.timeZone.transform(
                  this.fromDate.toString(),
                  timeFormate
                )}hrs (${this.timeZoneDetail?.abbreviation})`,
                "To: ",
                `${this.timeZone.transform(
                  this.toDate.toString(),
                  timeFormate
                )}hrs (${this.timeZoneDetail?.abbreviation})`,
              ],
              [
                "Duration: ",
                getDurationForDateRange(this.toDate, this.fromDate),
              ],
            ]
          : [
              [
                "Since: ",
                `${this.timeZone.transform(
                  formattedDuration.toString(),
                  timeFormate
                )}hrs (${this.timeZoneDetail?.abbreviation})`,
              ],
              [
                "Duration: ",
                `Past ${this.dateRangeValue.padStart(2, "0")}:00hrs`,
              ],
            ],
    });

    return doc;
  }

  setReportDetails(doc: jsPDFDocument, topY: number): jsPDFDocument {
    doc.autoTable({
      startY: topY,
      tableWidth: 61,
      rowStyles: {
        0: { fillColor: [242, 245, 241] },
        1: { fillColor: [255, 255, 255] },
      },
      columnStyles: {
        0: {
          valign: "middle",
          halign: "center",
        },
      },
      styles: {
        minCellHeight: 4,
        fontSize: 10,
        lineWidth: 0.1,
      },
      body: [
        ["Average urine"],
        [
          this.convertToNicuUnits(
            this.report?.avgUrine,
            "ml/hr",
            "ml/kg/hr",
            true
          ),
        ],
      ],
    });

    topY = doc.lastAutoTable.finalY + 6;

    doc.autoTable({
      startY: topY,
      columnStyles: {
        0: {
          minCellWidth: 20,
          maxCellWidth: 20,
          valign: "middle",
          halign: "center",
        },
        1: {
          minCellWidth: 20,
          maxCellWidth: 20,
          valign: "middle",
          halign: "center",
        },
        2: {
          minCellWidth: 20,
          maxCellWidth: 20,
          valign: "middle",
          halign: "center",
        },
      },
      rowStyles: {
        0: { fillColor: [242, 245, 241] },
        1: { fillColor: [255, 255, 255] },
      },
      styles: {
        minCellHeight: 4,
        fontSize: 10,
        lineWidth: 0.1,
      },
      body: [
        ["Total intake", "Total output", "Net"],
        [
          this.convertToNicuUnits(this.report?.intakeReport, "ml", "ml"),
          this.convertToNicuUnits(this.report?.outputReport, "ml", "ml"),
          this.convertToNicuUnits(this.report?.netReport, "ml", "ml"),
        ],
      ],
    });

    return doc;
  }

  convertToNicuUnits(
    amount,
    adultUnitName,
    N_PUnitName,
    checkPatientType = false
  ) {
    if (
      !checkPatientType ||
      this.currentPatient?.patientType === PatientType.Adult
    ) {
      return amount === 0 ? `0${adultUnitName}` : `${amount}${adultUnitName}`;
    }
    return amount === 0
      ? `0${N_PUnitName}`
      : `${(amount / this.currentPatient["currentWeight"]).toFixed(
          3
        )}${N_PUnitName}`;
  }

  setPdfFileFooter(doc: jsPDFDocument): jsPDFDocument {
    // set footer
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.line(8, 323 - 34, 202, 323 - 34);

      doc.setFontSize(6);
      doc.text(
        `Page ${String(i)} of ${String(pageCount)}, Report created on RADAR`,
        10,
        323 - 31
      );
      doc.text(
        `Printed on: ${this.timeZone.transform(
          new Date().toString(),
          "DD-MM-yyyy H:mm"
        )}` +
          " " +
          `(${this.timeZoneDetail?.abbreviation})`,
        210 - 45,
        323 - 31
      );
    }

    return doc;
  }

  launchPdfViewer(doc: jsPDFDocument): void {
    const pdfWindow = window.open("", "", "width=700,height=900");
    pdfWindow.document.write(
      "<iframe style='width: 100%; height: 70rem' src='" +
        doc.output("datauristring") +
        "' frameborder='0'></iframe>"
    );
    pdfWindow.document.close();
  }

  print() {
    let doc: jsPDFDocument = new jsPDF();
    doc = this.setPdfFileNameAndHeader(doc);

    let topY = doc.lastAutoTable.finalY + 5;
    doc = this.setPatientInfo(doc, topY);

    topY = doc.lastAutoTable.finalY + 6;
    doc = this.setReportHeader(doc, topY);

    topY = doc.lastAutoTable.finalY + 6;
    doc = this.setReportDetails(doc, topY);

    doc = this.setPdfFileFooter(doc);
    this.launchPdfViewer(doc);
  }

  getPatientAge() {
    const ageObj = this._currentPatient?.age;

    return displayAge(
      ageObj,
      false,
      this._currentPatient.patientType,
      this._currentPatient.dob
    );
  }
}
