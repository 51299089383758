import { useState } from "react";
import { environment } from "src/environments/environment";

const baseUrl = `${environment.apiUrl}`;

function useApi() {
  const [loading, setLoading] = useState<boolean>(false);
  const authToken = localStorage.getItem("token");

  interface ApiParams {
    url: string;
    options?: any;
  }
  const apiFn = async ({
    url,
    options,
  }: ApiParams): Promise<{
    data: any;
    response: any | null;
    error: string | null;
  }> => {
    try {
      const updateBody = (data: any) => {
        if (data instanceof FormData) {
          return data;
        }
        return JSON.stringify(data);
      };

      const updateHeaders = (): HeadersInit => {
        let tempHeaders: any = {
          Authorization: authToken ? "Bearer " + authToken : undefined,
          "Content-Type": "application/json",
          ...(options?.headers ?? {}),
        };
        if (options?.body instanceof FormData) {
          delete tempHeaders["Content-Type"];
        }

        for (let header in tempHeaders) {
          if (!tempHeaders[header]) {
            delete tempHeaders[header];
          }
        }
        return tempHeaders;
      };

      const headers = updateHeaders();
      setLoading(true);
      const response = await fetch(baseUrl + url, {
        ...options,
        body: options?.body && updateBody(options?.body),
        headers: headers && Object.keys(headers).length ? headers : undefined,
      });
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.message;
        setLoading(false);
        return {
          data: null,
          response: null,
          error: errorMessage ? errorMessage : "Error Occurred",
        };
      }
      setLoading(false);
      if (options?.download) {
        const blob = await response.blob();
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "download";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          return { data: null, response: "File downloaded", error: null };
        }
      }
      const result = await response.json();
      return { data: result, response: result, error: null };
    } catch (error: any) {
      setLoading(false);
      return {
        data: null,
        response: null,
        error: error?.message ? error?.message : "Error Occurred",
      };
    }
  };

  return { apiFn, loading };
}

export default useApi;
