import { configureStore } from "@reduxjs/toolkit";
import healthcheckReducer from "./reactSlice/healthcheckSlice";
import icdNotesReducer from "./reactSlice/icdNotesSlice";

const store = configureStore({
  reducer: {
    healthcheck: healthcheckReducer,
    icdCodes: icdNotesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
