import React from 'react';
import './searchInput.css'; 

interface ICDCode {
  code: string;
  description: string;
}

interface SearchInputProps {
  searchData: string;
  resultData: ICDCode[];
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSelectCode: (code: ICDCode) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchData,
  resultData,
  onSearchChange,
  onSearchSubmit,
  onSelectCode,
}) => {
  return (
    <div className="search-dropdown-container">
      <div className="search-container">
        <img
          src="assets/icons/search.svg"
          alt="Search"
          className="search-icon"
        />
        <input
          type="text"
          value={searchData}
          className="search-input"
          onChange={onSearchChange}
          onKeyDown={onSearchSubmit}
        />
      </div>

      <div className="dropdown-list">
        {resultData.map((icdCode, index) => (
          <div
            key={index}
            className="dropdown-item"
            onClick={() => onSelectCode(icdCode)}
          >
            {icdCode.code} - {icdCode.description}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchInput;
