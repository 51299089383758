export const environment = {
    production: false,
    apiUrl: "https://demo.cloudphysicianworld.org/api/",
    devResUrl: "https://demo.cloudphysicianworld.org/api/",
    vitalsUrl: "https://demo.cloudphysicianworld.org/api/",
    ptzApiUrl: "https://demo.cloudphysicianworld.org/api/",
    socketUrl: "https://demo.cloudphysicianworld.org/",
    aiSocketUrl: "https://demo.cloudphysicianworld.org/api_ai/",
    billingUrl: "https://demo.cloudphysicianworld.org/api/",
    recaptchaKey: "6Le9MMAUAAAAAACoh3Dqm6siCb5ewnZ_svviFTbJ",
    passwordSecretKey: "CloudPhysician",
    wikiDomain: "api-public.cloudphysicianworld.org",
    s3region: "ap-south-1",
    socketPath: "/api/CP-EMR",
    s3bucket: "emr-dev-public",
};