import React from 'react';
import { Provider } from 'react-redux';
import IcdNotesModal from './icdNotesModal';
import store from '../../reactStore/reduxStore';
import { NoteContent } from '@notes/models/notes.model';

interface IcdNotesWrapperProps {
  noteData: NoteContent;
  CPMRN: any;
  encounters: any;
  onClose: () => void;
  editMode?: boolean;  
}

const IcdNotesWrapper: React.FC<IcdNotesWrapperProps> = ({
  noteData,
  CPMRN,
  encounters,
  onClose,
  editMode = true, 
}) => (
  <Provider store={store}>
    <IcdNotesModal 
      noteData={noteData} 
      CPMRN={CPMRN} 
      encounters={encounters} 
      onClose={onClose} 
    />
  </Provider>
);

export default IcdNotesWrapper;
