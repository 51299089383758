import { useSelector } from "react-redux";
import "./showIcdModalStyles.css";
import { RootState } from "../reactStore/reduxStore";

interface ShowIcdModalProps {
  onClose: () => void;
}

interface ICDCode {
  code: string;
  description: string; 
}

const ShowIcdModal: React.FC<ShowIcdModalProps> = ({ onClose }) => {
  const icdCodes = useSelector((state: RootState) => state.icdCodes.icdCodes || []);

  const handleCodesCopy = () => {
    const allCodes = icdCodes
      .map((term: ICDCode) => `${term.description} (${term.code})`)
      .join(", ");
    
    navigator.clipboard.writeText(allCodes)
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="show-icd-modal-content">
        <header className="show-icd-modal-header">
          <h1 className="modal-title">ICD Code Review</h1>
          <div className="modal-buttons">
            <button className="modal-button" onClick={handleCodesCopy}>
              <img src="assets/icons/copy.svg" alt="Copy" />
            </button>
            <button onClick={handleClose} className="modal-button">
              <img src="assets/icons/close.svg" alt="Close" />
            </button>
          </div>
        </header>

        <div className="modal-body">
          <div className="terms-list">
            {icdCodes.map((term: ICDCode, index) => (
              <div key={index} className="term-item">
                <span className="term-text">{term.description} ({term.code})</span> 
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowIcdModal;

