import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: "app-label-badge",
  templateUrl: "./label-badge.component.html",
  styleUrls: ["./label-badge.component.scss"],
})
export class LabelBadgeComponent implements OnInit {
  @Input() labelData: any;
  @Input() iris_class;

  @Input() variant: string = "";
  badgeClass: string = "";

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.labelData?.icon)
      this.iconRegistry.addSvgIconLiteral(
        `${this.labelData.icon["iconName"]}`,
        this.sanitizer.bypassSecurityTrustHtml(this.labelData.icon["iconUrl"])
      );

    this.setBadgeClass();
  }

  setBadgeClass() {
    if (this.hasIrisClass()) {
      this.setIrisClass();
      return;
    }
    if (this.hasVariant()) {
      this.setVariantClass();
    }
  }

  setVariantClass() {
    // Splitting the variant into shape and color
    const [shape, color] = this.variant.split("-");
    if (shape && color) {
      // Setting badge class based on shape and color
      this.badgeClass = `iris-badge-${shape} iris-badge-${shape}--${color}`;
    }
  }

  setIrisClass() {
    // Setting badge class based on custom Iris class
    this.badgeClass = this.iris_class;
  }

  hasVariant() {
    // Checking if variant is present
    return !!this.variant;
  }

  hasIrisClass() {
    // Checking if custom Iris class is present
    return !!this.iris_class;
  }
}
