import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../reactStore/reduxStore";
import {
  updateTermsList,
  fetchICDCodes,
  removeTerm,
  icdShowStatusState,
} from "../../reactStore/reactSlice/icdNotesSlice";
import useApi from "../../reactStore/Hooks/useApi";
import AttentionModal from "../AttentionModal/attentionModal";
import { NoteContent } from "@notes/models/notes.model";
import "./icdNotesModalStyles.css";
import SearchInput from "../SearchInput/searchInput";

interface IcdNotesModalProps {
  noteData: NoteContent;
  CPMRN: any;
  encounters: any;
  onClose: () => void;
}

interface ICDCode {
  code: string;
  description: string;
}

const IcdNotesModal: React.FC<IcdNotesModalProps> = ({
  noteData,
  CPMRN,
  encounters,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchData, setSearchData] = useState("");
  const [resultData, setResultData] = useState<ICDCode[]>([]);
  const [selectedTerms, setSelectedTerms] = useState<ICDCode[]>([]);
  const [isAttentionOpen, setIsAttentionOpen] = useState(false);

  const { icdCodes, loading } = useSelector(
    (state: RootState) => state.icdCodes
  );
  
  useEffect(() => {
    dispatch(fetchICDCodes({ CPMRN, encounters, noteData }));
  }, [dispatch, CPMRN, encounters, noteData]);
  
  const { apiFn } = useApi();

  const handleSearchInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setSearchData(value);

    if (value.length < 3) {
      setResultData([]);
      return;
    }

    try {
      const { response, error } = await apiFn({
        url: `icd?s=${value}`,
        options: { method: "GET" },
      });

      if (error) {
        console.error("API error:", error);
        return;
      }

      if (response && response.data) {
        const updatedData: ICDCode[] = response.data
          .filter((item: any) => item.code && item.description)
          .map((item: any) => ({
            code: item.code,
            description: item.description,
          }));
        setResultData(updatedData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleSelectCode = (code: ICDCode) => {
    if (code.code && code.description) {
      setSelectedTerms((prev) => [...prev, code]);
      dispatch(updateTermsList([code]));
    }
    setSearchData("");
    setResultData([]);
  };

  const handleDeleteTerm = (term: string) => {
    const [code] = term.split(" - ");

    setSelectedTerms((prev) => prev.filter((t) => t.code !== code));
    dispatch(removeTerm(code));
  };

  const combinedTermsList = [
    ...new Set([
      ...icdCodes
        .map((codeObj) => `${codeObj.code} - ${codeObj.description}`)
        .filter(Boolean),
      ...selectedTerms
        .map((term) => `${term.code} - ${term.description}`)
        .filter(Boolean),
    ]),
  ];

  const handleClose = () => {
    onClose();
  };

  const handleSearchSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSearchData(e.currentTarget.value);
    }
  };

  const handleCopyCodes = () => {
    const allCodes = [
      ...icdCodes.map((codeObj) => `${codeObj.code} - ${codeObj.description}`),
      ...selectedTerms.map((term) => `${term.code} - ${term.description}`),
    ].join(", ");
    
    navigator.clipboard.writeText(allCodes)
  };

  const handleCancel = () => {
    setIsAttentionOpen(true);
  };

  const handleSave = () => {
    onClose();
    dispatch(icdShowStatusState(false));
  };

  const handleYesCloseModals = () => {
    setIsAttentionOpen(false);
    onClose(); 
  };

  return (
    <div className="modal-overlay">
      <div className="icd-notes-modal-content">
        <header className="icd-notes-modal-header">
          <h1 className="modal-title">ICD Code Review</h1>
          <div className="modal-buttons">
            <button className="modal-button" onClick={handleCopyCodes}>
              <img src="assets/icons/copy.svg" alt="Copy" />
            </button>
            <button onClick={handleClose} className="modal-button">
              <img src="assets/icons/close.svg" alt="Close" />
            </button>
          </div>
        </header>

        <div className="modal-body">
          <SearchInput
            searchData={searchData}
            resultData={resultData}
            onSearchChange={handleSearchInputChange}
            onSearchSubmit={handleSearchSubmit}
            onSelectCode={handleSelectCode}
          />

          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="terms-list">
              {combinedTermsList.map((term, index) => (
                <div key={index} className="term-item">
                  <span className="term-text">{term}</span>
                  <button
                    onClick={() => handleDeleteTerm(term)}
                    className="delete-button"
                  >
                    <img src="assets/icons/delete.svg" alt="Delete" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button
            onClick={handleCancel}
            className="cancel-button"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="save-button"
            disabled={loading}
          >
            Save
          </button>
        </div>
      </div>

      {isAttentionOpen && (
        <AttentionModal setIsAttentionOpen={setIsAttentionOpen} handleYesClose={handleYesCloseModals} />
      )}
    </div>
  );
};

export default IcdNotesModal;
